const anchorageArray = [
    {
        name: "Cowards Cove",
        coordinates: {
            latitude: 49.25613,
            longitude: -123.26498,
        },
        type: "anchorage",
        anchoring: {
            protectedFromWinds: ["north", "east", "south", "west"],
            bottomType: "sand",
            anchorHolding: "good",
        },
        features: {
            dinghyLanding: false,
            hiking: false,
            camping: false,
            beaches: true,
        },
        warnings: "Low tides expose mud flats",
        notes: null,
        boatCapacity: 'low', // low, medium, high (high is many boats)
        popularity: 'low', // low, medium, high (high is many boats)
        score: 60,
        lastUpdated: "2024-09-06",
        links: null
    },
    {
        name: "Kitsilano Beach",
        coordinates: {
            latitude: 49.27875,
            longitude: -123.1573,
        },
        type: "anchorage",
        anchoring: {
            protectedFromWinds: ["east", "south"],
            bottomType: "sand",
            anchorHolding: "good",
        },
        features: {
            dinghyLanding: false,
            hiking: false,
            camping: false,
            beaches: true,
        },
        warnings: "High boat traffic",
        notes: null,
        boatCapacity: 'medium', // low, medium, high (high is many boats)
        popularity: 'high', // low, medium, high (high is many boats)
        score: 80,
        lastUpdated: "2024-09-06",
        links: null
    },
    {
        name: "False Creek",
        coordinates: {
            latitude: 49.2693,
            longitude: -123.1235,
        },
        type: "anchorage",
        anchoring: {
            protectedFromWinds: ["north", "east", "south", "west"],
            bottomType: "mud",
            anchorHolding: "good",
        },
        features: {
            dinghyLanding: true,
            hiking: false,
            camping: false,
            beaches: true,
        },
        warnings: "High boat traffic, very busy area",
        notes: null,
        boatCapacity: 'medium', // low, medium, high (high is many boats)
        popularity: 'high', // low, medium, high (high is many boats)
        score: 80,
        lastUpdated: "2024-09-06",
        links: null
    },
    {
        name: "Manion Bay",
        coordinates: {
            latitude: 49.3853,
            longitude: -123.33024,
        },
        type: "anchorage",
        anchoring: {
            protectedFromWinds: ["north", "south", "west"],
            bottomType: "sand",
            anchorHolding: "good",
        },
        features: {
            dinghyLanding: true,
            hiking: true,
            camping: false,
            beaches: true,
        },
        warnings: null,
        notes: null,
        boatCapacity: 'medium', // low, medium, high (high is many boats)
        popularity: 'high', // low, medium, high (high is many boats)
        score: 80,
        lastUpdated: "2024-09-06",
        links: null
    },
    {
        name: "Halkett Bay",
        coordinates: {
            latitude: 49.452284,
            longitude: -123.329,
        },
        type: "anchorage",
        anchoring: {
            protectedFromWinds: ["north", "south", "west"],
            bottomType: ["sand", "rock"],
            anchorHolding: "good",
        },
        features: {
            dinghyLanding: true,
            hiking: true,
            camping: true,
            beaches: true,
        },
        warnings: "Dinghy dock is often under repair.",
        notes: "There are public mooring bupys available along the SW shore.",
        boatCapacity: 'low', // low, medium, high (high is many boats)
        popularity: 'medium', // low, medium, high (high is many boats)
        score: 85,
        lastUpdated: "2024-09-06",
        links: "https://bcparks.ca/halkett-bay-marine-park/"
    },
    {
        name: "Port Graves",
        coordinates: {
            latitude: 49.47345,
            longitude: -123.3549,
        },
        type: "anchorage",
        anchoring: {
            protectedFromWinds: ["north", "east", "west"],
            bottomType: "sand",
            anchorHolding: "good",
        },
        features: {
            dinghyLanding: true,
            hiking: true,
            camping: false,
            beaches: true,
        },
        warnings: "Anchor in the area off the docks and between them to avoid snagging logging cables on the bottom.",
        notes: null,
        boatCapacity: 'medium', // low, medium, high (high is many boats)
        popularity: 'medium', // low, medium, high (high is many boats)
        score: 88,
        lastUpdated: "2024-09-06",
        links: "https://vimarina.ca/latest-news/gambier-island-anchorage-west-bay-centre-bay-port-graves-halkett-bay/"
    },
    {
        name: "Centre Bay",
        coordinates: {
            latitude: 49.4757,
            longitude: -123.3791,
        },
        type: "anchorage",
        anchoring: {
            protectedFromWinds: ["north", "east", "west"],
            bottomType: "mud",
            anchorHolding: "good",
        },
        features: {
            dinghyLanding: true,
            hiking: false,
            camping: false,
            beaches: true,
        },
        warnings: null,
        notes: null,
        boatCapacity: 'medium', // low, medium, high (high is many boats)
        popularity: 'medium', // low, medium, high (high is many boats)
        score: 70,
        lastUpdated: "2024-09-06",
        links: "https://vimarina.ca/latest-news/gambier-island-anchorage-west-bay-centre-bay-port-graves-halkett-bay/"
    },
    {
        name: "West Bay",
        coordinates: {
            latitude: 49.46862,
            longitude: -123.402,
        },
        type: "anchorage",
        anchoring: {
            protectedFromWinds: ["north", "east", "west"],
            bottomType: "mud",
            anchorHolding: "good",
        },
        features: {
            dinghyLanding: false,
            hiking: false,
            camping: false,
            beaches: true,
        },
        warnings: null,
        notes: null,
        boatCapacity: 'medium', // low, medium, high (high is many boats)
        popularity: 'medium', // low, medium, high (high is many boats)
        score: 75,
        lastUpdated: "2024-09-06",
        links: "https://vimarina.ca/latest-news/gambier-island-anchorage-west-bay-centre-bay-port-graves-halkett-bay/"
    },
    {
        name: "Plumper Cove",
        coordinates: {
            latitude: 49.4020,
            longitude: -123.4740,
        },
        type: "anchorage",
        anchoring: {
            protectedFromWinds: ["south", "east", "west"],
            bottomType: "sand",
            anchorHolding: "good",
        },
        features: {
            dinghyLanding: true,
            hiking: true,
            camping: true,
            beaches: true,
        },
        warnings: null,
        notes: null,
        boatCapacity: 'medium', // low, medium, high (high is many boats)
        popularity: 'high', // low, medium, high (high is many boats)
        score: 85,
        lastUpdated: "2024-09-06",
        links: "https://bcparks.ca/plumper-cove-marine-park/"
    },
    {
        name: "Buccaneer Bay",
        coordinates: {
            latitude: 49.49587,
            longitude: -123.9862,
        },
        type: "anchorage",
        anchoring: {
            protectedFromWinds: ["south", "east", "west"],
            bottomType: "sand",
            anchorHolding: "good",
        },
        features: {
            dinghyLanding: true,
            hiking: true,
            camping: true,
            beaches: true,
        },
        warnings: null,
        notes: null,
        boatCapacity: 'medium', // low, medium, high (high is many boats)
        popularity: 'high', // low, medium, high (high is many boats)
        score: 90,
        lastUpdated: "2024-09-06",
        links: null
    },
    {
        name: "Bargain Bay",
        coordinates: {
            latitude: 49.614898,
            longitude: -124.0368,
        },
        type: "anchorage",
        anchoring: {
            protectedFromWinds: ["north", "south", "east", "west"],
            bottomType: ["rock", "sand"],
            anchorHolding: "fair",
        },
        features: {
            dinghyLanding: false,
            hiking: false,
            camping: false,
            beaches: true,
        },
        warnings: null,
        notes: null,
        boatCapacity: 'low', // low, medium, high (high is many boats)
        popularity: 'medium', // low, medium, high (high is many boats)
        score: 80,
        lastUpdated: "2024-09-06",
        links: null
    },
    {
        name: "Pendar Harbour",
        coordinates: {
            latitude: 49.62964,
            longitude: -124.0225,
        },
        type: "anchorage",
        anchoring: {
            protectedFromWinds: ["north", "south", "east", "west"],
            bottomType: "mud",
            anchorHolding: "good",
        },
        features: {
            dinghyLanding: true,
            hiking: false,
            camping: false,
            beaches: true,
        },
        warnings: null,
        notes: null,
        boatCapacity: 'high', // low, medium, high (high is many boats)
        popularity: 'high', // low, medium, high (high is many boats)
        score: 75,
        lastUpdated: "2024-09-06",
        links: null
    },
    {
        name: "Chatterbox Falls",
        coordinates: {
            latitude: 50.20494,
            longitude: -123.7729,
        },
        type: "anchorage",
        anchoring: {
            protectedFromWinds: ["north", "east", "west"],
            bottomType: "mud",
            anchorHolding: "fair",
        },
        features: {
            dinghyLanding: true,
            hiking: true,
            camping: true,
            beaches: true,
        },
        warnings: null,
        notes: null,
        boatCapacity: 'high', // low, medium, high (high is many boats)
        popularity: 'high', // low, medium, high (high is many boats)
        score: 90,
        lastUpdated: "2024-09-06",
        links: null
    },
    {
        name: "Sooke Harbour",
        coordinates: {
            latitude: 48.3590,
            longitude: -123.7223,
        },
        type: "anchorage",
        anchoring: {
            protectedFromWinds: ["north", "east", "west", "south"],
            bottomType: "mud",
            anchorHolding: "good",
        },
        features: {
            dinghyLanding: true,
            hiking: false,
            camping: false,
            beaches: true,
        },
        warnings: null,
        notes: null,
        boatCapacity: 'medium', // low, medium, high (high is many boats)
        popularity: 'medium', // low, medium, high (high is many boats)
        score: 90,
        lastUpdated: "2024-09-06",
        links: null
    },
    {
        name: "Cabbage Island",
        coordinates: {
            latitude: 48.79645,
            longitude: -123.0877,
        },
        type: "anchorage",
        anchoring: {
            protectedFromWinds: ["north", "east", "south"],
            bottomType: "sand",
            anchorHolding: "good",
        },
        features: {
            dinghyLanding: false,
            hiking: false,
            camping: true,
            beaches: true,
        },
        warnings: null,
        notes: null,
        boatCapacity: 'low', // low, medium, high (high is many boats)
        popularity: 'high', // low, medium, high (high is many boats)
        score: 95,
        lastUpdated: "2024-09-06",
        links: null
    },
    {
        name: "Hamilton Beach",
        coordinates: {
            latitude: 48.77465,
            longitude: -123.26926,
        },
        type: "anchorage",
        anchoring: {
            protectedFromWinds: ["north", "west", "south"],
            bottomType: "sand",
            anchorHolding: "good",
        },
        features: {
            dinghyLanding: true,
            hiking: false,
            camping: false,
            beaches: true,
        },
        warnings: null,
        notes: null,
        boatCapacity: 'high', // low, medium, high (high is many boats)
        popularity: 'high', // low, medium, high (high is many boats)
        score: 85,
        lastUpdated: "2024-09-06",
        links: null
    },
    {
        name: "Poet's Cove",
        coordinates: {
            latitude: 48.75147,
            longitude: -123.2334,
        },
        type: "anchorage",
        anchoring: {
            protectedFromWinds: ["north", "west", "east"],
            bottomType: "sand",
            anchorHolding: "good",
        },
        features: {
            dinghyLanding: true,
            hiking: false,
            camping: false,
            beaches: true,
        },
        warnings: null,
        notes: null,
        boatCapacity: 'medium', // low, medium, high (high is many boats)
        popularity: 'medium', // low, medium, high (high is many boats)
        score: 85,
        lastUpdated: "2024-09-06",
        links: null
    },
    {
        name: "Otter Bay",
        coordinates: {
            latitude: 48.7973,
            longitude: -123.3088,
        },
        type: "anchorage",
        anchoring: {
            protectedFromWinds: ["north", "south", "east"],
            bottomType: "mud",
            anchorHolding: "good",
        },
        features: {
            dinghyLanding: false,
            hiking: false,
            camping: false,
            beaches: true,
        },
        warnings: null,
        notes: null,
        boatCapacity: 'medium', // low, medium, high (high is many boats)
        popularity: 'low', // low, medium, high (high is many boats)
        score: 80,
        lastUpdated: "2024-09-06",
        links: null
    },
    {
        name: "Boot Cove",
        coordinates: {
            latitude: 48.79112,
            longitude: -123.198,
        },
        type: "anchorage",
        anchoring: {
            protectedFromWinds: ["north", "south", "West", "east"],
            bottomType: "mud",
            anchorHolding: "good",
        },
        features: {
            dinghyLanding: false,
            hiking: false,
            camping: false,
            beaches: true,
        },
        warnings: null,
        notes: null,
        boatCapacity: 'low', // low, medium, high (high is many boats)
        popularity: 'low', // low, medium, high (high is many boats)
        score: 80,
        lastUpdated: "2024-09-06",
        links: null
    },
    {
        name: "Miners Bay",
        coordinates: {
            latitude: 48.8523,
            longitude: -123.3048,
        },
        type: "anchorage",
        anchoring: {
            protectedFromWinds: ["north", "south", "West", "east"],
            bottomType: ["sand", "rock"],
            anchorHolding: "poor",
        },
        features: {
            dinghyLanding: true,
            hiking: false,
            camping: true,
            beaches: true,
        },
        warnings: "fast moving currents",
        notes: null,
        boatCapacity: 'low', // low, medium, high (high is many boats)
        popularity: 'low', // low, medium, high (high is many boats)
        score: 80,
        lastUpdated: "2024-09-06",
        links: null
    },
    {
        name: "Montague Harbour",
        coordinates: {
            latitude: 48.8917,
            longitude: -123.3975,
        },
        type: "anchorage",
        anchoring: {
            protectedFromWinds: ["north", "south", "West", "east"],
            bottomType: "mud",
            anchorHolding: "good",
        },
        features: {
            dinghyLanding: true,
            hiking: false,
            camping: false,
            beaches: true,
        },
        warnings: null,
        notes: null,
        boatCapacity: 'high', // low, medium, high (high is many boats)
        popularity: 'high', // low, medium, high (high is many boats)
        score: 88,
        lastUpdated: "2024-09-06",
        links: null
    },
    {
        name: "Ganges Harbour",
        coordinates: {
            latitude: 48.8543,
            longitude: -123.4935,
        },
        type: "anchorage",
        anchoring: {
            protectedFromWinds: ["north", "south", "West", "east"],
            bottomType: "mud",
            anchorHolding: "good",
        },
        features: {
            dinghyLanding: true,
            hiking: true,
            camping: false,
            beaches: true,
        },
        warnings: null,
        notes: null,
        boatCapacity: 'high', // low, medium, high (high is many boats)
        popularity: 'high', // low, medium, high (high is many boats)
        score: 88,
        lastUpdated: "2024-09-06",
        links: null
    },
    {
        name: "Telegraph Harbour",
        coordinates: {
            latitude: 48.9812,
            longitude: -123.6713,
        },
        type: "anchorage",
        anchoring: {
            protectedFromWinds: ["north", "West", "east"],
            bottomType: "mud",
            anchorHolding: "good",
        },
        features: {
            dinghyLanding: true,
            hiking: true,
            camping: false,
            beaches: true,
        },
        warnings: null,
        notes: null,
        boatCapacity: 'low', // low, medium, high (high is many boats)
        popularity: 'medium', // low, medium, high (high is many boats)
        score: 85,
        lastUpdated: "2024-09-06",
        links: null
    },
    {
        name: "Clark Bay",
        coordinates: {
            latitude: 48.9834,
            longitude: -123.6508,
        },
        type: "anchorage",
        anchoring: {
            protectedFromWinds: ["north", "West", "south"],
            bottomType: "mud",
            anchorHolding: "good",
        },
        features: {
            dinghyLanding: true,
            hiking: true,
            camping: false,
            beaches: true,
        },
        warnings: null,
        notes: null,
        boatCapacity: 'high', // low, medium, high (high is many boats)
        popularity: 'low', // low, medium, high (high is many boats)
        score: 80,
        lastUpdated: "2024-09-06",
        links: null
    },
    {
        name: "North Cove",
        coordinates: {
            latitude: 49.0160,
            longitude: -123.6909,
        },
        type: "anchorage",
        anchoring: {
            protectedFromWinds: ["east", "West", "south"],
            bottomType: "rock",
            anchorHolding: "fair",
        },
        features: {
            dinghyLanding: false,
            hiking: true,
            camping: false,
            beaches: true,
        },
        warnings: "exposed to northerly winds",
        notes: null,
        boatCapacity: 'medium', // low, medium, high (high is many boats)
        popularity: 'low', // low, medium, high (high is many boats)
        score: 75,
        lastUpdated: "2024-09-06",
        links: null
    },
    {
        name: "Pirates Cove",
        coordinates: {
            latitude: 49.0970,
            longitude: -123.7298,
        },
        type: "anchorage",
        anchoring: {
            protectedFromWinds: ["east", "West", "north"],
            bottomType: "sand",
            anchorHolding: "fair",
        },
        features: {
            dinghyLanding: true,
            hiking: false,
            camping: true,
            beaches: true,
        },
        warnings: null,
        notes: null,
        boatCapacity: 'low', // low, medium, high (high is many boats)
        popularity: 'medium', // low, medium, high (high is many boats)
        score: 75,
        lastUpdated: "2024-09-06",
        links: null
    },
    {
        name: "Pirates Cove 2",
        coordinates: {
            latitude: 49.0932,
            longitude: -123.7250,
        },
        type: "anchorage",
        anchoring: {
            protectedFromWinds: ["east", "West", "north"],
            bottomType: "sand",
            anchorHolding: "fair",
        },
        features: {
            dinghyLanding: false,
            hiking: false,
            camping: false,
            beaches: true,
        },
        warnings: "exposed to southerly winds",
        notes: null,
        boatCapacity: 'low', // low, medium, high (high is many boats)
        popularity: 'medium', // low, medium, high (high is many boats)
        score: 75,
        lastUpdated: "2024-09-06",
        links: null
    },
    {
        name: "Herring Bay",
        coordinates: {
            latitude: 49.0846,
            longitude: -123.7161,
        },
        type: "anchorage",
        anchoring: {
            protectedFromWinds: ["east", "north"],
            bottomType: "sand",
            anchorHolding: "fair",
        },
        features: {
            dinghyLanding: false,
            hiking: false,
            camping: false,
            beaches: true,
        },
        warnings: null,
        notes: null,
        boatCapacity: 'low', // low, medium, high (high is many boats)
        popularity: 'medium', // low, medium, high (high is many boats)
        score: 75,
        lastUpdated: "2024-09-06",
        links: null
    },
    {
        name: "Silva Bay",
        coordinates: {
            latitude: 49.1504,
            longitude: -123.6957,
        },
        type: "anchorage",
        anchoring: {
            protectedFromWinds: ["south", "west", "east", "north"],
            bottomType: "rock",
            anchorHolding: "fair",
        },
        features: {
            dinghyLanding: true,
            hiking: false,
            camping: false,
            beaches: true,
        },
        warnings: null,
        notes: null,
        boatCapacity: 'medium', // low, medium, high (high is many boats)
        popularity: 'high', // low, medium, high (high is many boats)
        score: 80,
        lastUpdated: "2024-09-06",
        links: null
    },
    {
        name: "Degnen Bay",
        coordinates: {
            latitude: 49.1355,
            longitude: -123.7146,
        },
        type: "anchorage",
        anchoring: {
            protectedFromWinds: ["south", "west", "east", "north"],
            bottomType: "sand",
            anchorHolding: "good",
        },
        features: {
            dinghyLanding: true,
            hiking: false,
            camping: false,
            beaches: true,
        },
        warnings: null,
        notes: null,
        boatCapacity: 'medium', // low, medium, high (high is many boats)
        popularity: 'high', // low, medium, high (high is many boats)
        score: 75,
        lastUpdated: "2024-09-06",
        links: null
    },
    {
        name: "Pilot Bay",
        coordinates: {
            latitude: 49.1966,
            longitude: -123.8546,
        },
        type: "anchorage",
        anchoring: {
            protectedFromWinds: ["south", "west"],
            bottomType: "sand",
            anchorHolding: "good",
        },
        features: {
            dinghyLanding: false,
            hiking: false,
            camping: false,
            beaches: true,
        },
        warnings: "exposed to wind and swell from the Strait of Georgia",
        notes: null,
        boatCapacity: 'medium', // low, medium, high (high is many boats)
        popularity: 'high', // low, medium, high (high is many boats)
        score: 70,
        lastUpdated: "2024-09-06",
        links: null
    },
    {
        name: "Taylor Bay",
        coordinates: {
            latitude: 49.19355,
            longitude: -123.8626,
        },
        type: "anchorage",
        anchoring: {
            protectedFromWinds: ["south", "north", "east"],
            bottomType: "sand",
            anchorHolding: "good",
        },
        features: {
            dinghyLanding: false,
            hiking: false,
            camping: false,
            beaches: true,
        },
        warnings: null,
        notes: null,
        boatCapacity: 'low', // low, medium, high (high is many boats)
        popularity: 'low', // low, medium, high (high is many boats)
        score: 80,
        lastUpdated: "2024-09-06",
        links: null
    },
    {
        name: "Nanaimo Harbour",
        coordinates: {
            latitude: 49.1781,
            longitude: -123.9290,
        },
        type: "anchorage",
        anchoring: {
            protectedFromWinds: ["south", "north", "east", "west"],
            bottomType: "mud",
            anchorHolding: "good",
        },
        features: {
            dinghyLanding: true,
            hiking: false,
            camping: false,
            beaches: true,
        },
        warnings: null,
        notes: null,
        boatCapacity: 'high', // low, medium, high (high is many boats)
        popularity: 'high', // low, medium, high (high is many boats)
        score: 65,
        lastUpdated: "2024-09-06",
        links: null
    },
    {
        name: "Deep Bay",
        coordinates: {
            latitude: 49.4639,
            longitude: -124.7332,
        },
        type: "anchorage",
        anchoring: {
            protectedFromWinds: ["south", "north", "east", "west"],
            bottomType: "mud",
            anchorHolding: "good",
        },
        features: {
            dinghyLanding: true,
            hiking: false,
            camping: false,
            beaches: true,
        },
        warnings: "congested area with several permanent private mooring buoys",
        notes: null,
        boatCapacity: 'low', // low, medium, high (high is many boats)
        popularity: 'high', // low, medium, high (high is many boats)
        score: 70,
        lastUpdated: "2024-09-06",
        links: null
    },
    {
        name: "Tribune Bay",
        coordinates: {
            latitude: 49.5209,
            longitude: -124.6290,
        },
        type: "anchorage",
        anchoring: {
            protectedFromWinds: ["north", "east", "west"],
            bottomType: "sand",
            anchorHolding: "good",
        },
        features: {
            dinghyLanding: false,
            hiking: false,
            camping: false,
            beaches: true,
        },
        warnings: null,
        notes: null,
        boatCapacity: 'high', // low, medium, high (high is many boats)
        popularity: 'high', // low, medium, high (high is many boats)
        score: 90,
        lastUpdated: "2024-09-06",
        links: null
    },
    {
        name: "Deep Bay Jedediah Island",
        coordinates: {
            latitude: 49.5052,
            longitude: -124.2146,
        },
        type: "anchorage",
        anchoring: {
            protectedFromWinds: ["south", "east", "west"],
            bottomType: "rock",
            anchorHolding: "fair",
        },
        features: {
            dinghyLanding: false,
            hiking: false,
            camping: false,
            beaches: true,
        },
        warnings: "Narrow anchorage, may need to stern tie",
        notes: null,
        boatCapacity: 'low', // low, medium, high (high is many boats)
        popularity: 'medium', // low, medium, high (high is many boats)
        score: 90,
        lastUpdated: "2024-09-06",
        links: null
    },
    {
        name: "Russell Island",
        coordinates: {
            latitude: 48.7488,
            longitude: -123.4112,
        },
        type: "anchorage",
        anchoring: {
            protectedFromWinds: ["south", "east", "west", "north"],
            bottomType: "rock",
            anchorHolding: "fair",
        },
        features: {
            dinghyLanding: true,
            hiking: false,
            camping: false,
            beaches: true,
        },
        warnings: null,
        notes: null,
        boatCapacity: 'low', // low, medium, high (high is many boats)
        popularity: 'low', // low, medium, high (high is many boats)
        score: 80,
        lastUpdated: "2024-09-06",
        links: null
    },
    {
        name: "James Bay",
        coordinates: {
            latitude: 48.8418,
            longitude: -123.3986,
        },
        type: "anchorage",
        anchoring: {
            protectedFromWinds: ["south", "east", "west"],
            bottomType: "sand",
            anchorHolding: "good",
        },
        features: {
            dinghyLanding: false,
            hiking: false,
            camping: true,
            beaches: true,
        },
        warnings: null,
        notes: null,
        boatCapacity: 'low', // low, medium, high (high is many boats)
        popularity: 'low', // low, medium, high (high is many boats)
        score: 85,
        lastUpdated: "2024-09-06",
        links: null
    },
    {
        name: "Selby Cove",
        coordinates: {
            latitude: 48.8333,
            longitude: -123.3977,
        },
        type: "anchorage",
        anchoring: {
            protectedFromWinds: ["south", "east", "north"],
            bottomType: "sand",
            anchorHolding: "good",
        },
        features: {
            dinghyLanding: false,
            hiking: false,
            camping: false,
            beaches: true,
        },
        warnings: null,
        notes: null,
        boatCapacity: 'low', // low, medium, high (high is many boats)
        popularity: 'medium', // low, medium, high (high is many boats)
        score: 85,
        lastUpdated: "2024-09-06",
        links: null
    },
    {
        name: "Annette Inlet",
        coordinates: {
            latitude: 48.8212,
            longitude: -123.3857,
        },
        type: "anchorage",
        anchoring: {
            protectedFromWinds: ["south", "east", "north", "west"],
            bottomType: "sand",
            anchorHolding: "good",
        },
        features: {
            dinghyLanding: false,
            hiking: false,
            camping: false,
            beaches: true,
        },
        warnings: null,
        notes: null,
        boatCapacity: 'low', // low, medium, high (high is many boats)
        popularity: 'low', // low, medium, high (high is many boats)
        score: 85,
        lastUpdated: "2024-09-06",
        links: null
    },
    {
        name: "Secret Island",
        coordinates: {
            latitude: 48.8236,
            longitude: -123.3912,
        },
        type: "anchorage",
        anchoring: {
            protectedFromWinds: ["south", "east", "north", "west"],
            bottomType: "sand",
            anchorHolding: "good",
        },
        features: {
            dinghyLanding: false,
            hiking: false,
            camping: false,
            beaches: true,
        },
        warnings: null,
        notes: null,
        boatCapacity: 'low', // low, medium, high (high is many boats)
        popularity: 'medium', // low, medium, high (high is many boats)
        score: 85,
        lastUpdated: "2024-09-06",
        links: null
    },
    {
        name: "Sidney Spit",
        coordinates: {
            latitude: 48.6438,
            longitude: -123.3361,
        },
        type: "anchorage",
        anchoring: {
            protectedFromWinds: ["south", "east"],
            bottomType: "sand",
            anchorHolding: "good",
        },
        features: {
            dinghyLanding: true,
            hiking: true,
            camping: true,
            beaches: true,
        },
        warnings: null,
        notes: null,
        boatCapacity: 'medium', // low, medium, high (high is many boats)
        popularity: 'high', // low, medium, high (high is many boats)
        score: 90,
        lastUpdated: "2024-09-06",
        links: null
    },
    {
        name: "Cadboro Bay",
        coordinates: {
            latitude:48.45578,
            longitude: -123.2934,
        },
        type: "anchorage",
        anchoring: {
            protectedFromWinds: ["north", "west", "east"],
            bottomType: "sand",
            anchorHolding: "good",
        },
        features: {
            dinghyLanding: true,
            hiking: false,
            camping: false,
            beaches: true,
        },
        warnings: null,
        notes: null,
        boatCapacity: 'high', // low, medium, high (high is many boats)
        popularity: 'high', // low, medium, high (high is many boats)
        score: 80,
        lastUpdated: "2024-09-06",
        links: null
    },
    {
        name: "Oak Bay",
        coordinates: {
            latitude: 48.42712,
            longitude: -123.3041,
        },
        type: "anchorage",
        anchoring: {
            protectedFromWinds: ["north", "west"],
            bottomType: "sand",
            anchorHolding: "good",
        },
        features: {
            dinghyLanding: true,
            hiking: false,
            camping: false,
            beaches: true,
        },
        warnings: null,
        notes: null,
        boatCapacity: 'medium', // low, medium, high (high is many boats)
        popularity: 'high', // low, medium, high (high is many boats)
        score: 80,
        lastUpdated: "2024-09-06",
        links: null
    },
    {
        name: "Snuggery Cove",
        coordinates: {
            latitude: 48.5564,
            longitude: -124.4125,
        },
        type: "anchorage",
        anchoring: {
            protectedFromWinds: ["east", "north", "west"],
            bottomType: "sand",
            anchorHolding: "good",
        },
        features: {
            dinghyLanding: true,
            hiking: true,
            camping: true,
            beaches: true,
        },
        warnings: null,
        notes: null,
        boatCapacity: 'medium', // low, medium, high (high is many boats)
        popularity: 'medium', // low, medium, high (high is many boats)
        score: 83,
        lastUpdated: "2024-09-06",
        links: null
    },
    {
        name: "Princess Cove",
        coordinates: {
            latitude: 48.9431,
            longitude: -123.5560,
        },
        type: "anchorage",
        anchoring: {
            protectedFromWinds: ["east", "south", "west"],
            bottomType: "sand",
            anchorHolding: "good",
        },
        features: {
            dinghyLanding: false,
            hiking: false,
            camping: false,
            beaches: true,
        },
        warnings: "narrow anchorage, stern tie may be required",
        notes: null,
        boatCapacity: 'low', // low, medium, high (high is many boats)
        popularity: 'medium', // low, medium, high (high is many boats)
        score: 85,
        lastUpdated: "2024-09-06",
        links: null
    },
    {
        name: "Conover Cove",
        coordinates: {
            latitude: 48.9376,
            longitude: -123.5455,
        },
        type: "anchorage",
        anchoring: {
            protectedFromWinds: ["north", "east", "south", "west"],
            bottomType: "sand",
            anchorHolding: "good",
        },
        features: {
            dinghyLanding: true,
            hiking: true,
            camping: true,
            beaches: true,
        },
        warnings: "narrow anchorage, stern tie may be required",
        notes: null,
        boatCapacity: 'low', // low, medium, high (high is many boats)
        popularity: 'medium', // low, medium, high (high is many boats)
        score: 85,
        lastUpdated: "2024-09-06",
        links: null
    },
    {
        name: "Sibell Bay",
        coordinates: {
            latitude: 48.9911,
            longitude: -123.7811,
        },
        type: "anchorage",
        anchoring: {
            protectedFromWinds: ["north", "east", "west"],
            bottomType: "sand",
            anchorHolding: "good",
        },
        features: {
            dinghyLanding: false,
            hiking: false,
            camping: false,
            beaches: true,
        },
        warnings: null,
        notes: null,
        boatCapacity: 'low', // low, medium, high (high is many boats)
        popularity: 'medium', // low, medium, high (high is many boats)
        score: 65,
        lastUpdated: "2024-09-06",
        links: null
    },
    {
        name: "Tent Island",
        coordinates: {
            latitude: 48.9281,
            longitude: -123.6309,
        },
        type: "anchorage",
        anchoring: {
            protectedFromWinds: ["north", "east"],
            bottomType: "sand",
            anchorHolding: "good",
        },
        features: {
            dinghyLanding: false,
            hiking: false,
            camping: true,
            beaches: true,
        },
        warnings: null,
        notes: null,
        boatCapacity: 'low', // low, medium, high (high is many boats)
        popularity: 'medium', // low, medium, high (high is many boats)
        score: 85,
        lastUpdated: "2024-09-06",
        links: null
    },
    {
        name: "Royal Cove",
        coordinates: {
            latitude: 48.7348,
            longitude: -123.372,
        },
        type: "anchorage",
        anchoring: {
            protectedFromWinds: ["east", "south", "west"],
            bottomType: "sand",
            anchorHolding: "good",
        },
        features: {
            dinghyLanding: true,
            hiking: true,
            camping: true,
            beaches: true,
        },
        warnings: null,
        notes: null,
        boatCapacity: 'low', // low, medium, high (high is many boats)
        popularity: 'medium', // low, medium, high (high is many boats)
        score: 85,
        lastUpdated: "2024-09-06",
        links: null
    },
    {
        name: "Princess Bay",
        coordinates: {
            latitude: 48.7197,
            longitude: -123.3696,
        },
        type: "anchorage",
        anchoring: {
            protectedFromWinds: ["east", "north", "west"],
            bottomType: "mud",
            anchorHolding: "good",
        },
        features: {
            dinghyLanding: true,
            hiking: true,
            camping: true,
            beaches: true,
        },
        warnings: "subject to ferry wash. Stern tie may be required",
        notes: null,
        boatCapacity: 'low', // low, medium, high (high is many boats)
        popularity: 'medium', // low, medium, high (high is many boats)
        score: 85,
        lastUpdated: "2024-09-06",
        links: null
    },
    {
        name: "Medicine Beach",
        coordinates: {
            latitude: 48.7600,
            longitude: -123.2647,
        },
        type: "anchorage",
        anchoring: {
            protectedFromWinds: ["south", "east", "north", "west"],
            bottomType: "mud",
            anchorHolding: "good",
        },
        features: {
            dinghyLanding: false,
            hiking: true,
            camping: false,
            beaches: true,
        },
        warnings: null,
        notes: null,
        boatCapacity: 'medium', // low, medium, high (high is many boats)
        popularity: 'medium', // low, medium, high (high is many boats)
        score: 85,
        lastUpdated: "2024-09-06",
        links: null
    },
    {
        name: "Narvaez Bay",
        coordinates: {
            latitude: 48.7744,
            longitude: -123.1004,
        },
        type: "anchorage",
        anchoring: {
            protectedFromWinds: ["south", "east", "north", "west"],
            bottomType: ["sand", "rock"],
            anchorHolding: "fair",
        },
        features: {
            dinghyLanding: false,
            hiking: true,
            camping: true,
            beaches: true,
        },
        warnings: null,
        notes: null,
        boatCapacity: 'low', // low, medium, high (high is many boats)
        popularity: 'medium', // low, medium, high (high is many boats)
        score: 88,
        lastUpdated: "2024-09-06",
        links: null
    },
    {
        name: "Winter Cover",
        coordinates: {
            latitude: 48.8100,
            longitude: -123.1913,
        },
        type: "anchorage",
        anchoring: {
            protectedFromWinds: ["south", "east", "north", "west"],
            bottomType: ["sand", "rock"],
            anchorHolding: "fair",
        },
        features: {
            dinghyLanding: false,
            hiking: true,
            camping: false,
            beaches: true,
        },
        warnings: "reefs and rocks exposed at low tide, overall a shallow anchorage",
        notes: null,
        boatCapacity: 'medium', // low, medium, high (high is many boats)
        popularity: 'medium', // low, medium, high (high is many boats)
        score: 88,
        lastUpdated: "2024-09-06",
        links: null
    },
    {
        name: "Grace Harbour",
        coordinates: {
            latitude: 50.0523,
            longitude: -124.7445,
        },
        type: "anchorage",
        anchoring: {
            protectedFromWinds: ["south", "east", "north", "west"],
            bottomType: "mud",
            anchorHolding: "good",
        },
        features: {
            dinghyLanding: false,
            hiking: true,
            camping: false,
            beaches: true,
        },
        warnings: null,
        notes: null,
        boatCapacity: 'medium', // low, medium, high (high is many boats)
        popularity: 'high', // low, medium, high (high is many boats)
        score: 90,
        lastUpdated: "2024-09-06",
        links: null
    },
    {
        name: "Isabel Bay",
        coordinates: {
            latitude: 50.0527,
            longitude: -124.7288,
        },
        type: "anchorage",
        anchoring: {
            protectedFromWinds: ["south", "east", "west"],
            bottomType: "mud",
            anchorHolding: "good",
        },
        features: {
            dinghyLanding: false,
            hiking: false,
            camping: false,
            beaches: true,
        },
        warnings: null,
        notes: null,
        boatCapacity: 'low', // low, medium, high (high is many boats)
        popularity: 'high', // low, medium, high (high is many boats)
        score: 90,
        lastUpdated: "2024-09-06",
        links: null
    },
    {
        name: "Tenedos Bay",
        coordinates: {
            latitude: 50.1231,
            longitude: -124.6922,
        },
        type: "anchorage",
        anchoring: {
            protectedFromWinds: ["north", "east", "west"],
            bottomType: "mud",
            anchorHolding: "good",
        },
        features: {
            dinghyLanding: false,
            hiking: false,
            camping: true,
            beaches: true,
        },
        warnings: null,
        notes: null,
        boatCapacity: 'low', // low, medium, high (high is many boats)
        popularity: 'high', // low, medium, high (high is many boats)
        score: 90,
        lastUpdated: "2024-09-06",
        links: null
    },
    {
        name: "Tenedos Bay 2",
        coordinates: {
            latitude: 50.1269,
            longitude: -124.7038,
        },
        type: "anchorage",
        anchoring: {
            protectedFromWinds: ["north", "east", "west"],
            bottomType: "mud",
            anchorHolding: "good",
        },
        features: {
            dinghyLanding: false,
            hiking: false,
            camping: true,
            beaches: true,
        },
        warnings: null,
        notes: null,
        boatCapacity: 'medium', // low, medium, high (high is many boats)
        popularity: 'high', // low, medium, high (high is many boats)
        score: 90,
        lastUpdated: "2024-09-06",
        links: null
    },
    {
        name: "Prideaux Haven",
        coordinates: {
            latitude: 50.1418,
            longitude: -124.6861,
        },
        type: "anchorage",
        anchoring: {
            protectedFromWinds: ["south", "north", "east", "west"],
            bottomType: "mud",
            anchorHolding: "good",
        },
        features: {
            dinghyLanding: false,
            hiking: false,
            camping: false,
            beaches: true,
        },
        warnings: null,
        notes: null,
        boatCapacity: 'medium', // low, medium, high (high is many boats)
        popularity: 'high', // low, medium, high (high is many boats)
        score: 90,
        lastUpdated: "2024-09-06",
        links: null
    },
    {
        name: "Refuge Cove",
        coordinates: {
            latitude: 50.1240,
            longitude: -124.8425,
        },
        type: "anchorage",
        anchoring: {
            protectedFromWinds: ["south", "north", "east", "west"],
            bottomType: "mud",
            anchorHolding: "good",
        },
        features: {
            dinghyLanding: true,
            hiking: false,
            camping: false,
            beaches: true,
        },
        warnings: null,
        notes: null,
        boatCapacity: 'medium', // low, medium, high (high is many boats)
        popularity: 'high', // low, medium, high (high is many boats)
        score: 90,
        lastUpdated: "2024-09-06",
        links: null
    },
    {
        name: "Cortez Bay",
        coordinates: {
            latitude: 50.0637,
            longitude: -124.9331,
        },
        type: "anchorage",
        anchoring: {
            protectedFromWinds: ["south", "north", "east", "west"],
            bottomType: "mud",
            anchorHolding: "good",
        },
        features: {
            dinghyLanding: true,
            hiking: true,
            camping: false,
            beaches: true,
        },
        warnings: null,
        notes: null,
        boatCapacity: 'medium', // low, medium, high (high is many boats)
        popularity: 'high', // low, medium, high (high is many boats)
        score: 90,
        lastUpdated: "2024-09-06",
        links: null
    },
    {
        name: "Squirrel Cove",
        coordinates: {
            latitude: 50.1404,
            longitude: -124.9201,
        },
        type: "anchorage",
        anchoring: {
            protectedFromWinds: ["south", "north", "east", "west"],
            bottomType: "mud",
            anchorHolding: "good",
        },
        features: {
            dinghyLanding: false,
            hiking: false,
            camping: false,
            beaches: true,
        },
        warnings: null,
        notes: null,
        boatCapacity: 'medium', // low, medium, high (high is many boats)
        popularity: 'high', // low, medium, high (high is many boats)
        score: 90,
        lastUpdated: "2024-09-06",
        links: null
    },
    {
        name: "Von Donop Inlet",
        coordinates: {
            latitude: 50.1432,
            longitude: -124.9462,
        },
        type: "anchorage",
        anchoring: {
            protectedFromWinds: ["south", "north", "east", "west"],
            bottomType: "mud",
            anchorHolding: "good",
        },
        features: {
            dinghyLanding: false,
            hiking: false,
            camping: false,
            beaches: true,
        },
        warnings: null,
        notes: null,
        boatCapacity: 'medium', // low, medium, high (high is many boats)
        popularity: 'low', // low, medium, high (high is many boats)
        score: 90,
        lastUpdated: "2024-09-06",
        links: null
    },
    {
        name: "Teakerne Arm",
        coordinates: {
            latitude: 50.1984,
            longitude: -124.8434,
        },
        type: "anchorage",
        anchoring: {
            protectedFromWinds: ["north", "east", "west"],
            bottomType: "mud",
            anchorHolding: "good",
        },
        features: {
            dinghyLanding: false,
            hiking: false,
            camping: false,
            beaches: true,
        },
        warnings: null,
        notes: "beautiful waterfall nearby",
        boatCapacity: 'low', // low, medium, high (high is many boats)
        popularity: 'medium', // low, medium, high (high is many boats)
        score: 90,
        lastUpdated: "2024-09-06",
        links: null
    },
    {
        name: "East Teakerne Arm",
        coordinates: {
            latitude: 50.18366,
            longitude: -124.8208,
        },
        type: "anchorage",
        anchoring: {
            protectedFromWinds: ["north", "south", "west"],
            bottomType: "mud",
            anchorHolding: "good",
        },
        features: {
            dinghyLanding: false,
            hiking: true,
            camping: true,
            beaches: true,
        },
        warnings: null,
        notes: "beautiful waterfall nearby",
        boatCapacity: 'low', // low, medium, high (high is many boats)
        popularity: 'medium', // low, medium, high (high is many boats)
        score: 90,
        lastUpdated: "2024-09-06",
        links: null
    },
    {
        name: "Pendrell Sound",
        coordinates: {
            latitude: 50.2990,
            longitude: -124.7307,
        },
        type: "anchorage",
        anchoring: {
            protectedFromWinds: ["north", "east", "west"],
            bottomType: "mud",
            anchorHolding: "good",
        },
        features: {
            dinghyLanding: false,
            hiking: false,
            camping: false,
            beaches: true,
        },
        warnings: null,
        notes: null,
        boatCapacity: 'low', // low, medium, high (high is many boats)
        popularity: 'medium', // low, medium, high (high is many boats)
        score: 90,
        lastUpdated: "2024-09-06",
        links: null
    },
    {
        name: "Attwood Bay",
        coordinates: {
            latitude: 50.3145,
            longitude: -124.6676,
        },
        type: "anchorage",
        anchoring: {
            protectedFromWinds: ["north", "east", "west"],
            bottomType: "rock",
            anchorHolding: "fair",
        },
        features: {
            dinghyLanding: false,
            hiking: false,
            camping: false,
            beaches: true,
        },
        warnings: "deep anchorage, stern tie may be required",
        notes: null,
        boatCapacity: 'low', // low, medium, high (high is many boats)
        popularity: 'medium', // low, medium, high (high is many boats)
        score: 90,
        lastUpdated: "2024-09-06",
        links: null
    },
    {
        name: "Gorge Harbour",
        coordinates: {
            latitude: 50.0990,
            longitude: -125.0265,
        },
        type: "anchorage",
        anchoring: {
            protectedFromWinds: ["south", "north", "east", "west"],
            bottomType: "rock",
            anchorHolding: "fair",
        },
        features: {
            dinghyLanding: true,
            hiking: true,
            camping: false,
            beaches: true,
        },
        warnings: "deep anchorage, stern tie may be required",
        notes: null,
        boatCapacity: 'low', // low, medium, high (high is many boats)
        popularity: 'medium', // low, medium, high (high is many boats)
        score: 90,
        lastUpdated: "2024-09-06",
        links: null
    },
    {
        name: "Mansons Landing",
        coordinates: {
            latitude: 50.0736,
            longitude: -124.9852,
        },
        type: "anchorage",
        anchoring: {
            protectedFromWinds: ["south", "north", "east", "west"],
            bottomType: "sand",
            anchorHolding: "good",
        },
        features: {
            dinghyLanding: true,
            hiking: true,
            camping: false,
            beaches: true,
        },
        warnings: null,
        notes: null,
        boatCapacity: 'medium', // low, medium, high (high is many boats)
        popularity: 'medium', // low, medium, high (high is many boats)
        score: 90,
        lastUpdated: "2024-09-06",
        links: null
    },
    {
        name: "Kanish Bay",
        coordinates: {
            latitude: 50.2586,
            longitude: -125.3185,
        },
        type: "anchorage",
        anchoring: {
            protectedFromWinds: ["south", "north", "west"],
            bottomType: "mud",
            anchorHolding: "good",
        },
        features: {
            dinghyLanding: false,
            hiking: false,
            camping: false,
            beaches: true,
        },
        warnings: null,
        notes: null,
        boatCapacity: 'medium', // low, medium, high (high is many boats)
        popularity: 'low', // low, medium, high (high is many boats)
        score: 85,
        lastUpdated: "2024-09-06",
        links: null
    },
    {
        name: "Small Inlet",
        coordinates: {
            latitude: 50.2604,
            longitude: -125.2883,
        },
        type: "anchorage",
        anchoring: {
            protectedFromWinds: ["east", "south", "north", "west"],
            bottomType: "mud",
            anchorHolding: "good",
        },
        features: {
            dinghyLanding: false,
            hiking: false,
            camping: false,
            beaches: true,
        },
        warnings: null,
        notes: null,
        boatCapacity: 'medium', // low, medium, high (high is many boats)
        popularity: 'low', // low, medium, high (high is many boats)
        score: 85,
        lastUpdated: "2024-09-06",
        links: null
    },
    {
        name: "Bold Island",
        coordinates: {
            latitude: 50.1710,
            longitude: -125.1679,
        },
        type: "anchorage",
        anchoring: {
            protectedFromWinds: ["east", "south", "north", "west"],
            bottomType: "mud",
            anchorHolding: "good",
        },
        features: {
            dinghyLanding: false,
            hiking: false,
            camping: false,
            beaches: true,
        },
        warnings: null,
        notes: null,
        boatCapacity: 'low', // low, medium, high (high is many boats)
        popularity: 'medium', // low, medium, high (high is many boats)
        score: 85,
        lastUpdated: "2024-09-06",
        links: null
    },
    {
        name: "Elworthy Island",
        coordinates: {
            latitude: 50.1975,
            longitude: -124.7901,
        },
        type: "anchorage",
        anchoring: {
            protectedFromWinds: ["east", "south", "north", "west"],
            bottomType: ["rock", "mud"],
            anchorHolding: "fair",
        },
        features: {
            dinghyLanding: false,
            hiking: false,
            camping: false,
            beaches: true,
        },
        warnings: null,
        notes: null,
        boatCapacity: 'low', // low, medium, high (high is many boats)
        popularity: 'medium', // low, medium, high (high is many boats)
        score: 85,
        lastUpdated: "2024-09-06",
        links: null
    },
    {
        name: "Sargeant Bay",
        coordinates: {
            latitude: 49.4753,
            longitude: -123.8569,
        },
        type: "anchorage",
        anchoring: {
            protectedFromWinds: ["north", "west"],
            bottomType: "mud",
            anchorHolding: "good",
        },
        features: {
            dinghyLanding: false,
            hiking: false,
            camping: false,
            beaches: true,
        },
        warnings: null,
        notes: null,
        boatCapacity: 'low', // low, medium, high (high is many boats)
        popularity: 'low', // low, medium, high (high is many boats)
        score: 80,
        lastUpdated: "2024-09-06",
        links: null
    },
    {
        name: "Frenchman's Cove",
        coordinates: {
            latitude: 49.5077,
            longitude: -123.9489,
        },
        type: "anchorage",
        anchoring: {
            protectedFromWinds: ["south", "east", "north", "west"],
            bottomType: "rock",
            anchorHolding: "fair",
        },
        features: {
            dinghyLanding: true,
            hiking: true,
            camping: false,
            beaches: true,
        },
        warnings: "very narrow entrance, stern tie is required",
        notes: null,
        boatCapacity: 'low', // low, medium, high (high is many boats)
        popularity: 'medium', // low, medium, high (high is many boats)
        score: 80,
        lastUpdated: "2024-09-06",
        links: null
    },
    {
        name: "Smuggler Cove",
        coordinates: {
            latitude: 49.5085,
            longitude: -123.9462,
        },
        type: "anchorage",
        anchoring: {
            protectedFromWinds: ["south", "east", "north", "west"],
            bottomType: "rock",
            anchorHolding: "fair",
        },
        features: {
            dinghyLanding: true,
            hiking: true,
            camping: false,
            beaches: true,
        },
        warnings: "large swings in tide, stern tie may be required",
        notes: null,
        boatCapacity: 'low', // low, medium, high (high is many boats)
        popularity: 'medium', // low, medium, high (high is many boats)
        score: 80,
        lastUpdated: "2024-09-06",
        links: null
    },
    {
        name: "Secret Cove",
        coordinates: {
            latitude: 49.5330,
            longitude: -123.9656,
        },
        type: "anchorage",
        anchoring: {
            protectedFromWinds: ["south", "east", "north", "west"],
            bottomType: "mud",
            anchorHolding: "good",
        },
        features: {
            dinghyLanding: true,
            hiking: true,
            camping: false,
            beaches: true,
        },
        warnings: null,
        notes: null,
        boatCapacity: 'mediun', // low, medium, high (high is many boats)
        popularity: 'medium', // low, medium, high (high is many boats)
        score: 80,
        lastUpdated: "2024-09-06",
        links: null
    },
    {
        name: "Harness Island",
        coordinates: {
            latitude: 49.5930,
            longitude: -124.0174,
        },
        type: "anchorage",
        anchoring: {
            protectedFromWinds: ["south", "east", "north", "west"],
            bottomType: "mud",
            anchorHolding: "good",
        },
        features: {
            dinghyLanding: false,
            hiking: false,
            camping: false,
            beaches: true,
        },
        warnings: null,
        notes: null,
        boatCapacity: 'low', // low, medium, high (high is many boats)
        popularity: 'low', // low, medium, high (high is many boats)
        score: 75,
        lastUpdated: "2024-09-06",
        links: null
    },
    {
        name: "Bargain Bay",
        coordinates: {
            latitude: 49.6149,
            longitude: -124.0369,
        },
        type: "anchorage",
        anchoring: {
            protectedFromWinds: ["south", "east", "north", "west"],
            bottomType: "mud",
            anchorHolding: "good",
        },
        features: {
            dinghyLanding: true,
            hiking: false,
            camping: false,
            beaches: true,
        },
        warnings: null,
        notes: null,
        boatCapacity: 'medium', // low, medium, high (high is many boats)
        popularity: 'medium', // low, medium, high (high is many boats)
        score: 75,
        lastUpdated: "2024-09-06",
        links: null
    },
    {
        name: "Hardy Island",
        coordinates: {
            latitude: 49.7284,
            longitude: -124.2129,
        },
        type: "anchorage",
        anchoring: {
            protectedFromWinds: ["south", "east", "north", "west"],
            bottomType: "rock",
            anchorHolding: "fair",
        },
        features: {
            dinghyLanding: false,
            hiking: false,
            camping: false,
            beaches: true,
        },
        warnings: "deep anchorage, stern tie may be required",
        notes: null,
        boatCapacity: 'low', // low, medium, high (high is many boats)
        popularity: 'low', // low, medium, high (high is many boats)
        score: 70,
        lastUpdated: "2024-09-06",
        links: null
    },
    {
        name: "Frolander Bay",
        coordinates: {
            latitude: 49.7594,
            longitude: -124.2985,
        },
        type: "anchorage",
        anchoring: {
            protectedFromWinds: ["east", "north", "west"],
            bottomType: "rock",
            anchorHolding: "fair",
        },
        features: {
            dinghyLanding: false,
            hiking: false,
            camping: false,
            beaches: true,
        },
        warnings: "exposed to winds from the south",
        notes: null,
        boatCapacity: 'high', // low, medium, high (high is many boats)
        popularity: 'low', // low, medium, high (high is many boats)
        score: 70,
        lastUpdated: "2024-09-06",
        links: null
    },
    {
        name: "Mcrae Cove",
        coordinates: {
            latitude: 49.7492,
            longitude: -124.2785,
        },
        type: "anchorage",
        anchoring: {
            protectedFromWinds: ["east", "north", "west"],
            bottomType: "rock",
            anchorHolding: "fair",
        },
        features: {
            dinghyLanding: false,
            hiking: false,
            camping: false,
            beaches: true,
        },
        warnings: "exposed to winds from the south",
        notes: null,
        boatCapacity: 'medium', // low, medium, high (high is many boats)
        popularity: 'low', // low, medium, high (high is many boats)
        score: 75,
        lastUpdated: "2024-09-06",
        links: null
    },
    {
        name: "Green Bay",
        coordinates: {
            latitude: 49.7079,
            longitude: -124.0772,
        },
        type: "anchorage",
        anchoring: {
            protectedFromWinds: ["east", "north", "west"],
            bottomType: "hard mud",
            anchorHolding: "fair",
        },
        features: {
            dinghyLanding: false,
            hiking: false,
            camping: false,
            beaches: true,
        },
        warnings: "exposed to winds from the south, area is not well charted",
        notes: null,
        boatCapacity: 'medium', // low, medium, high (high is many boats)
        popularity: 'low', // low, medium, high (high is many boats)
        score: 70,
        lastUpdated: "2024-09-06",
        links: null
    },
    {
        name: "Savary Island",
        coordinates: {
            latitude: 49.9483,
            longitude: -124.7807,
        },
        type: "anchorage",
        anchoring: {
            protectedFromWinds: ["east", "south"],
            bottomType: "sand",
            anchorHolding: "good",
        },
        features: {
            dinghyLanding: true,
            hiking: true,
            camping: true,
            beaches: true,
        },
        warnings: "very exposed to north winds",
        notes: null,
        boatCapacity: 'medium', // low, medium, high (high is many boats)
        popularity: 'medium', // low, medium, high (high is many boats)
        score: 75,
        lastUpdated: "2024-09-06",
        links: null
    },
    {
        name: "Anderson Bay",
        coordinates: {
            latitude: 49.5192,
            longitude: -124.1385,
        },
        type: "anchorage",
        anchoring: {
            protectedFromWinds: ["east", "north", "west"],
            bottomType: "rock",
            anchorHolding: "fair",
        },
        features: {
            dinghyLanding: false,
            hiking: false,
            camping: false,
            beaches: true,
        },
        warnings: "shallow reef located in the bay",
        notes: null,
        boatCapacity: 'low', // low, medium, high (high is many boats)
        popularity: 'low', // low, medium, high (high is many boats)
        score: 75,
        lastUpdated: "2024-09-06",
        links: null
    },
    {
        name: "Sturt Bay",
        coordinates: {
            latitude: 49.7618,
            longitude: -124.5653,
        },
        type: "anchorage",
        anchoring: {
            protectedFromWinds: ["west", "north", "south"],
            bottomType: "mud",
            anchorHolding: "good",
        },
        features: {
            dinghyLanding: true,
            hiking: true,
            camping: false,
            beaches: true,
        },
        warnings: null,
        notes: null,
        boatCapacity: 'medium', // low, medium, high (high is many boats)
        popularity: 'medium', // low, medium, high (high is many boats)
        score: 80,
        lastUpdated: "2024-09-06",
        links: null
    },
    {
        name: "Blubber Bay",
        coordinates: {
            latitude: 49.7950,
            longitude: -124.6164,
        },
        type: "anchorage",
        anchoring: {
            protectedFromWinds: ["west", "east", "south"],
            bottomType: "mud",
            anchorHolding: "good",
        },
        features: {
            dinghyLanding: true,
            hiking: true,
            camping: false,
            beaches: true,
        },
        warnings: "exposed to north winds and ferry swell",
        notes: null,
        boatCapacity: 'medium', // low, medium, high (high is many boats)
        popularity: 'low', // low, medium, high (high is many boats)
        score: 65,
        lastUpdated: "2024-09-06",
        links: null
    },
    {
        name: "Morning Beach",
        coordinates: {
            latitude: 49.6025,
            longitude: -124.8377,
        },
        type: "anchorage",
        anchoring: {
            protectedFromWinds: ["north", "west", "east", "south"],
            bottomType: "sand",
            anchorHolding: "good",
        },
        features: {
            dinghyLanding: false,
            hiking: true,
            camping: false,
            beaches: true,
        },
        warnings: null,
        notes: null,
        boatCapacity: 'medium', // low, medium, high (high is many boats)
        popularity: 'high', // low, medium, high (high is many boats)
        score: 85,
        lastUpdated: "2024-09-06",
        links: null
    },
    {
        name: "Quathiaski Cove",
        coordinates: {
            latitude: 50.0501,
            longitude: -125.2235,
        },
        type: "anchorage",
        anchoring: {
            protectedFromWinds: ["north", "west", "east", "south"],
            bottomType: ["rock", "sand"],
            anchorHolding: "fair",
        },
        features: {
            dinghyLanding: true,
            hiking: true,
            camping: false,
            beaches: true,
        },
        warnings: null,
        notes: null,
        boatCapacity: 'medium', // low, medium, high (high is many boats)
        popularity: 'medium', // low, medium, high (high is many boats)
        score: 75,
        lastUpdated: "2024-09-06",
        links: null
    },
    {
        name: "Drew Harbour",
        coordinates: {
            latitude: 50.0978,
            longitude: -125.1938,
        },
        type: "anchorage",
        anchoring: {
            protectedFromWinds: ["north", "west", "east", "south"],
            bottomType: "sand",
            anchorHolding: "good",
        },
        features: {
            dinghyLanding: true,
            hiking: true,
            camping: true,
            beaches: true,
        },
        warnings: null,
        notes: null,
        boatCapacity: 'high', // low, medium, high (high is many boats)
        popularity: 'high', // low, medium, high (high is many boats)
        score: 85,
        lastUpdated: "2024-09-06",
        links: null
    },
    {
        name: "Galley Bay",
        coordinates: {
            latitude: 50.0724,
            longitude: -124.7894,        
        },
        type: "anchorage",
        anchoring: {
            protectedFromWinds: ["west", "east", "south"],
            bottomType: "rock",
            anchorHolding: "fair",
        },
        features: {
            dinghyLanding: false,
            hiking: true,
            camping: false,
            beaches: true,
        },
        warnings: null,
        notes: null,
        boatCapacity: 'medium', // low, medium, high (high is many boats)
        popularity: 'low', // low, medium, high (high is many boats)
        score: 70,
        lastUpdated: "2024-09-06",
        links: null
    },
    {
        name: "Mink Island",
        coordinates: {
            latitude: 50.1045,
            longitude: -124.7558,
        },
        type: "anchorage",
        anchoring: {
            protectedFromWinds: ["north", "west", "east", "south"],
            bottomType: "rock",
            anchorHolding: "fair",
        },
        features: {
            dinghyLanding: false,
            hiking: false,
            camping: false,
            beaches: true,
        },
        warnings: "narrow anchorage, stern tie may be required",
        notes: null,
        boatCapacity: 'low', // low, medium, high (high is many boats)
        popularity: 'medium', // low, medium, high (high is many boats)
        score: 80,
        lastUpdated: "2024-09-06",
        links: null
    },
    {
        name: "Comox Harbour",
        coordinates: {
            latitude: 49.6647,
            longitude: -124.9240,
        },
        type: "anchorage",
        anchoring: {
            protectedFromWinds: ["north", "west", "east", "south"],
            bottomType: "sand",
            anchorHolding: "good",
        },
        features: {
            dinghyLanding: true,
            hiking: false,
            camping: false,
            beaches: true,
        },
        warnings: null,
        notes: null,
        boatCapacity: 'medium', // low, medium, high (high is many boats)
        popularity: 'high', // low, medium, high (high is many boats)
        score: 75,
        lastUpdated: "2024-09-06",
        links: null
    },
    {
        name: "Storm Bay",
        coordinates: {
            latitude: 49.6600,
            longitude: -123.8218,
        },
        type: "anchorage",
        anchoring: {
            protectedFromWinds: ["north", "west", "east", "south"],
            bottomType: "mud",
            anchorHolding: "good",
        },
        features: {
            dinghyLanding: false,
            hiking: false,
            camping: false,
            beaches: true,
        },
        warnings: "Stern tie may be required",
        notes: null,
        boatCapacity: 'medium', // low, medium, high (high is many boats)
        popularity: 'low', // low, medium, high (high is many boats)
        score: 80,
        lastUpdated: "2024-09-06",
        links: null
    },
    {
        name: "Kunechin Point",
        coordinates: {
            latitude: 49.6321,
            longitude: -123.8018,
        },
        type: "anchorage",
        anchoring: {
            protectedFromWinds: ["north", "west", "south"],
            bottomType: "rock",
            anchorHolding: "unreliable",
        },
        features: {
            dinghyLanding: false,
            hiking: false,
            camping: true,
            beaches: true,
        },
        warnings: "Stern tie may be required, bottom is spotty and mostly rock",
        notes: null,
        boatCapacity: 'medium', // low, medium, high (high is many boats)
        popularity: 'low', // low, medium, high (high is many boats)
        score: 75,
        lastUpdated: "2024-09-06",
        links: null
    },
    {
        name: "Nine Mile Point",
        coordinates: {
            latitude: 49.5907,
            longitude: -123.7846,
        },
        type: "anchorage",
        anchoring: {
            protectedFromWinds: ["east"],
            bottomType: "sand",
            anchorHolding: "good",
        },
        features: {
            dinghyLanding: false,
            hiking: false,
            camping: true,
            beaches: true,
        },
        warnings: "Stern tie may be required, no wind protection",
        notes: null,
        boatCapacity: 'medium', // low, medium, high (high is many boats)
        popularity: 'low', // low, medium, high (high is many boats)
        score: 75,
        lastUpdated: "2024-09-06",
        links: null
    },
    {
        name: "Halfway Beach",
        coordinates: {
            latitude: 49.5984,
            longitude: -123.8189,
        },
        type: "anchorage",
        anchoring: {
            protectedFromWinds: ["west"],
            bottomType: "sand",
            anchorHolding: "good",
        },
        features: {
            dinghyLanding: false,
            hiking: false,
            camping: true,
            beaches: true,
        },
        warnings: "Stern tie may be required, no wind protection",
        notes: null,
        boatCapacity: 'medium', // low, medium, high (high is many boats)
        popularity: 'low', // low, medium, high (high is many boats)
        score: 80,
        lastUpdated: "2024-09-06",
        links: null
    },
    {
        name: "False Bay",
        coordinates: {
            latitude: 49.4966,
            longitude: -124.3576,
        },
        type: "anchorage",
        anchoring: {
            protectedFromWinds: ["north", "east", "south"],
            bottomType: "sand",
            anchorHolding: "good",
        },
        features: {
            dinghyLanding: false,
            hiking: false,
            camping: false,
            beaches: true,
        },
        warnings: null,
        notes: null,
        boatCapacity: 'medium', // low, medium, high (high is many boats)
        popularity: 'medium', // low, medium, high (high is many boats)
        score: 80,
        lastUpdated: "2024-10-09",
        links: null
    },
    {
        name: "Shearwater Hot Springs",
        coordinates: {
            latitude: 53.4492,
            longitude: -128.5598,
        },
        type: "anchorage",
        anchoring: {
            protectedFromWinds: ["south", "east", "west", "north"],
            bottomType: ["sand", "rock"],
            anchorHolding: "fair",
        },
        features: {
            dinghyLanding: false,
            hiking: false,
            camping: false,
            beaches: true,
        },
        warnings: null,
        notes: "Hot springs located on the beach. Two mooring buoys available",
        boatCapacity: 'medium', // low, medium, high (high is many boats)
        popularity: 'low', // low, medium, high (high is many boats)
        score: 85,
        lastUpdated: "2024-10-09",
        links: null
    },
    {
        name: "Bearskin Bay",
        coordinates: {
            latitude: 53.2530,
            longitude: -132.0727,
        },
        type: "anchorage",
        anchoring: {
            protectedFromWinds: ["south", "west", "north"],
            bottomType: "mud",
            anchorHolding: "good",
        },
        features: {
            dinghyLanding: true,
            hiking: true,
            camping: true,
            beaches: true,
        },
        warnings: null,
        notes: "Excellent place to provision",
        boatCapacity: 'medium', // low, medium, high (high is many boats)
        popularity: 'medium', // low, medium, high (high is many boats)
        score: 85,
        lastUpdated: "2024-10-09",
        links: null
    },
    {
        name: "Effingham Bay",
        coordinates: {
            latitude: 48.8774,
            longitude: -125.3097,
        },
        type: "anchorage",
        anchoring: {
            protectedFromWinds: ["east", "south", "west", "north"],
            bottomType: "rock",
            anchorHolding: "fair",
        },
        features: {
            dinghyLanding: false,
            hiking: false,
            camping: false,
            beaches: true,
        },
        warnings: null,
        notes: "Stern tire occasionally required",
        boatCapacity: 'medium', // low, medium, high (high is many boats)
        popularity: 'medium', // low, medium, high (high is many boats)
        score: 85,
        lastUpdated: "2024-10-09",
        links: null
    },
    {
        name: "Turtle Bay",
        coordinates: {
            latitude: 48.9169,
            longitude: -125.3270,
        },
        type: "anchorage",
        anchoring: {
            protectedFromWinds: ["east", "south", "west", "north"],
            bottomType: "rock",
            anchorHolding: "fair",
        },
        features: {
            dinghyLanding: false,
            hiking: false,
            camping: false,
            beaches: true,
        },
        warnings: null,
        notes: null,
        boatCapacity: 'medium', // low, medium, high (high is many boats)
        popularity: 'medium', // low, medium, high (high is many boats)
        score: 85,
        lastUpdated: "2024-10-09",
        links: null
    },
    {
        name: "Nettle Island",
        coordinates: {
            latitude: 48.9287,
            longitude: -125.2528,
        },
        type: "anchorage",
        anchoring: {
            protectedFromWinds: ["east", "south", "west", "north"],
            bottomType: "rock",
            anchorHolding: "fair",
        },
        features: {
            dinghyLanding: false,
            hiking: false,
            camping: false,
            beaches: true,
        },
        warnings: null,
        notes: null,
        boatCapacity: 'medium', // low, medium, high (high is many boats)
        popularity: 'medium', // low, medium, high (high is many boats)
        score: 85,
        lastUpdated: "2024-10-09",
        links: null
    },
]

export default anchorageArray;