import { FaFish, FaWater } from "react-icons/fa";
import { TiWeatherStormy, TiWeatherWindyCloudy } from "react-icons/ti";
import { VscBook, VscFeedback } from "react-icons/vsc";
import { RiArticleLine } from "react-icons/ri";
import { useLocation } from "react-router-dom";
import { TbZoomInArea } from "react-icons/tb";
import logoImage from '../../images/logos/transparentLogoWhite.png';
import React, { useState, useEffect } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { Sidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";
import { LuTable } from "react-icons/lu";
import { WiDayThunderstorm } from "react-icons/wi";
import { GrResources } from "react-icons/gr";
import { IoBoatOutline } from "react-icons/io5";
import { IoFishOutline } from "react-icons/io5";
import { FaMapMarkedAlt } from "react-icons/fa";
import {
  FaAngleDoubleLeft,
  FaAngleDoubleRight,
  FaGlobeAmericas,
  FaQuestion,
} from 'react-icons/fa';
import { IoDocumentTextOutline } from "react-icons/io5";
import { BiDonateHeart } from "react-icons/bi";
import FishingReport from "../pages/weeklyFishingReports";

const SidebarPro = () => {

    const location = useLocation();

    const largeIconSize = 25;
    const smallIconSize = 20;

    const color = isActive => isActive ? 'red' : 'white';
    const marginAmount = 10;

    const [collapsed, setCollapsed] = useState(true);
    const [toggled, setToggled] = useState(false);
    const [weatherMenuItems, setWeatherMenuItems] = useState([
      {
        name: 'Weather Map',
        url: '/',
        image: <TiWeatherStormy color={color} size={largeIconSize} />
      },
      {
        name: 'Weather Table',
        url: '/weatherTable',
        image: <LuTable color={color} size={largeIconSize} />
      },
      {
        name: 'Weather Forecast',
        url: '/weatherForecast',
        image: <TiWeatherWindyCloudy color={color} size={largeIconSize} />
      },
    ]);

    const [boatingMenuItems, setBoatingMenuItems] = useState([
      {
        name: 'Destinations Map',
        url: '/destinationsMap',
        image: <FaMapMarkedAlt color={color} size={largeIconSize} />
      },
      {
        name: 'Tides',
        url: '/tides',
        image: <FaWater color={color} size={largeIconSize} />
      },
    ]);

    const [resourceMenuItems, setResourceMenuItems] = useState([
      {
        name: 'Tutorials',
        url: '/helpPage',
        image: <FaQuestion color={color} size={largeIconSize} />
      },
      {
        name: 'Feedback',
        url: '/feedback',
        image: <VscFeedback color={'green'} size={largeIconSize} />
      },
      {
        name: 'Support This Project',
        url: 'https://buymeacoffee.com/sportyapp',
        image: <BiDonateHeart color={color} size={largeIconSize} />
      },
      {
        name: 'Terms and Conditions',
        url: '/termsAndConditions',
        image: <IoDocumentTextOutline color={color} size={largeIconSize} />
      },
    ]);


    const [fishingMenuItems, setFishingMenuItems] = useState([
      {
        name: 'Fishing Map',
        url: '/fishingMap',
        image: <FaFish color={color} size={largeIconSize} />
      },
      {
        name: 'Area Status',
        url: '/areaStatus',
        image: <TbZoomInArea color={color} size={largeIconSize} />
      },
      {
        name: 'Area Map',
        url: '/fishingAreaMap',
        image: <FaGlobeAmericas color={color} size={largeIconSize} />
      },
      // {
      //   name: 'Weekly Fishing Reports',
      //   url: '/FishingReportJune10',
      //   image: <RiArticleLine color={color} size={largeIconSize} />
      // },
    ]);

    const handleCollapsedChange = () => {
        setCollapsed(!collapsed);
      };
    
    const handleToggleSidebar = (value) => {
        setToggled(value);
      };

    const handleMenuItemClick = () => {
      if(collapsed) {
        setCollapsed(true);
      }
    }


  return (
    <Sidebar
      collapsed={collapsed}
      className="sidebar-pro"
      style={{position: 'relative', height: '100vh', color: 'white', zIndex: 999}}
      toggled={toggled}
      backgroundColor='rgba(20,20,30,1)'
      onToggle={handleToggleSidebar}
      width="240px"
      collapsedWidth="70px"
    >
      <Menu>
        {collapsed ? (
          <MenuItem
            icon={<FaAngleDoubleRight />}
            onClick={handleCollapsedChange}
          ></MenuItem>
        ) : (
          <MenuItem
            suffix={<FaAngleDoubleLeft />}
            onClick={handleCollapsedChange}
          >
            <img src={logoImage} alt='logo' className='sidebar-logo' />
          </MenuItem>
        )}
      </Menu>
      <Menu
        className="sidebar-menu-container"
        menuItemStyles={{
          button: ({ level, active }) => {
            // only apply styles on first level elements of the tree
            if (active)
            return {
              position: 'relative',
              // make border only 70%
              '&:before': {
                content: '""',
                position: 'absolute',
                bottom: 0,
                left: '10%', // Adjust this value to control the width
                width: '80f%', // Adjust this value to control the width
                height: '.5px',
                backgroundColor: 'orange',
              },
            };
          },
          button: {
            // the active class will be added automatically by react router
            // so we can use it to style the active menu item
            [`&:hover`]: {
              color: 'orange',
            },
          },
        }}
      >
      <SubMenu label="Weather" className="sidebar-menu-item" icon={<WiDayThunderstorm color={color} size={largeIconSize} />}>
        {weatherMenuItems.map((item, index) => (
          <MenuItem
              component={<Link to={item.url} />}
              // icon={item.image}
              active={location.pathname === item.url} // Compare current URL with item URL
              className={location.pathname === item.url ? "sidebar-menu-item-active" : "sidebar-menu-item"}
              key={index}
          >
              {item.name}
          </MenuItem>
        ))}
      </SubMenu>
      <SubMenu style={{marginTop: marginAmount}} label="Fishing" className="sidebar-menu-item" icon={<IoFishOutline color={color} size={largeIconSize} />}>
        {fishingMenuItems.map((item, index) => (
          <MenuItem
              component={<Link to={item.url} />}
              // icon={item.image}
              active={location.pathname === item.url} // Compare current URL with item URL
              className={location.pathname === item.url ? "sidebar-menu-item-active" : "sidebar-menu-item"}
              key={index}
          >
              {item.name}
          </MenuItem>
        ))}
      </SubMenu>
      <SubMenu style={{marginTop: marginAmount}} label="Boating" className="sidebar-menu-item" icon={<IoBoatOutline color={color} size={largeIconSize} />}>
        {boatingMenuItems.map((item, index) => (
          <MenuItem
              component={<Link to={item.url} />}
              // icon={item.image}
              active={location.pathname === item.url} // Compare current URL with item URL
              className={location.pathname === item.url ? "sidebar-menu-item-active" : "sidebar-menu-item"}
              key={index}
          >
              {item.name}
          </MenuItem>
        ))}
      </SubMenu>
      <SubMenu style={{marginTop: marginAmount}} label="Resources" className="sidebar-menu-item" icon={<GrResources color={color} size={largeIconSize} />}>
        {resourceMenuItems.map((item, index) => (
          <MenuItem
              component={<Link to={item.url} />}
              // icon={item.image}
              active={location.pathname === item.url} // Compare current URL with item URL
              className={location.pathname === item.url ? "sidebar-menu-item-active" : "sidebar-menu-item"}
              key={index}
          >
              {item.name}
          </MenuItem>
        ))}
      </SubMenu>
      </Menu>
    </Sidebar>
  );
};

export default SidebarPro;


