
const fuelDockArray = [
    {
        name: "Captain's Cove Marina",
        coordinates: {
            // 49.113882424441265, -123.07678396069119
            latitude: 49.1138,
            longitude: -123.0767,
        },
        type: "fuelDock",
        features: {
            phone: "(604) 946-1244",
            hours: null,
            fuelTypes: null,
            services: null,
        },
        warnings: null,
        notes: null,
        lastUpdated: "2024-10-27",
    },
    {
        name: "Mosquito Creek Marina",
        coordinates: {
            // 49.313789027288195, -123.0884220809286
            latitude: 49.3137,
            longitude: -123.0884,
        },
        type: "fuelDock",
        features: {
            phone: "(604) 987-4113",
            hours: null,
            fuelTypes: null,
            services: null,
        },
        warnings: null,
        notes: null,
        lastUpdated: "2024-10-27",
    },
    {
        name: "Seycove Marina",
        coordinates: {
            // 49.33184836177284, -122.93929844517844
            latitude: 49.3318,
            longitude: -122.9392,
        },
        type: "fuelDock",
        features: {
            phone: "(604) 929-1251",
            hours: null,
            fuelTypes: null,
            services: null,
        },
        warnings: null,
        notes: null,
        lastUpdated: "2024-10-27",
    },
    {
        name: "Richmond Marina",
        coordinates: {
            // 49.19833820787804, -123.13659228842515
            latitude: 49.1983,
            longitude: -123.1365,
        },
        type: "fuelDock",
        features: {
            phone: "(604) 278-2181",
            hours: null,
            fuelTypes: null,
            services: null,
        },
        warnings: null,
        notes: null,
        lastUpdated: "2024-10-27",
    },
    {
        name: "Vancouver Marina",
        coordinates: {
            // 49.191027497747044, -123.1366561970649
            latitude: 49.1910,
            longitude: -123.1366,
        },
        type: "fuelDock",
        features: {
            phone: "(604) 278-9787",
            hours: null,
            fuelTypes: null,
            services: null,
        },
        warnings: null,
        notes: null,
        lastUpdated: "2024-10-27",
    },
    {
        name: "Steveston Chevron",
        coordinates: {
            // 49.124171613741304, -123.1904786568341
            latitude: 49.1241,
            longitude: -123.1904,
        },
        type: "fuelDock",
        features: {
            phone: "(604) 277-4712",
            hours: null,
            fuelTypes: null,
            services: null,
        },
        warnings: null,
        notes: null,
        lastUpdated: "2024-10-27",
    },
    {
        name: "Crescent Beach Marina",
        coordinates: {
            // 49.056603940393074, -122.8705134317003
            latitude: 49.0566,
            longitude: -122.8705,
        },
        type: "fuelDock",
        features: {
            phone: "(604) 538-9666",
            hours: null,
            fuelTypes: null,
            services: null,
        },
        warnings: null,
        notes: null,
        lastUpdated: "2024-10-27",
    },
    {
        name: "Coal Harbour Chevron",
        coordinates: {
            // 49.294572389728245, -123.1182813963807
            latitude: 49.2945,
            longitude: -123.1182,
        },
        type: "fuelDock",
        features: {
            phone: "(604) 681-7725",
            hours: null,
            fuelTypes: null,
            services: null,
        },
        warnings: null,
        notes: null,
        lastUpdated: "2024-10-27",
    },
    {
        name: "False Creek Fuels",
        coordinates: {
            // 49.275195640873164, -123.13716415263444
            latitude: 49.2751,
            longitude: -123.1371,
        },
        type: "fuelDock",
        features: {
            phone: "(604) 638-0209",
            hours: null,
            fuelTypes: null,
            services: null,
        },
        warnings: null,
        notes: null,
        lastUpdated: "2024-10-27",
    },
    {
        name: "Fisherman’s Cove Marina",
        coordinates: {
            // 49.35497654582294, -123.27576247577365
            latitude: 49.3549,
            longitude: -123.2757,
        },
        type: "fuelDock",
        features: {
            phone: "(604) 921-7333",
            hours: null,
            fuelTypes: null,
            services: null,
        },
        warnings: null,
        notes: null,
        lastUpdated: "2024-10-27",
    },
    {
        name: "Sewell’s Marina",
        coordinates: {
            // 49.37651627810859, -123.27362790752139
            latitude: 49.3765,
            longitude: -123.2735,
        },
        type: "fuelDock",
        features: {
            phone: "(604) 921-3474",
            hours: null,
            fuelTypes: null,
            services: null,
        },
        warnings: null,
        notes: null,
        lastUpdated: "2024-10-27",
    },
    {
        name: "Sunset Marina",
        coordinates: {
            // 49.405360625132, -123.2442839325502
            latitude: 49.4053,
            longitude: -123.2442,
        },
        type: "fuelDock",
        features: {
            phone: "(604) 921-7476",
            hours: null,
            fuelTypes: null,
            services: null,
        },
        warnings: null,
        notes: null,
        lastUpdated: "2024-10-27",
    },
    {
        name: "Lions Bay Marina",
        coordinates: {
            // 49.45461924839045, -123.23929332704417
            latitude: 49.4546,
            longitude: -123.2392,
        },
        type: "fuelDock",
        features: {
            phone: "(604) 921-7510",
            hours: null,
            fuelTypes: null,
            services: null,
        },
        warnings: null,
        notes: null,
        lastUpdated: "2024-10-27",
    },
    {
        name: "Bathgate Marina",
        coordinates: {
            // 49.7576731536645, -123.93928753012108
            latitude: 49.7576,
            longitude: -123.9392,
        },
        type: "fuelDock",
        features: {
            phone: "(604) 883-2222",
            hours: null,
            fuelTypes: null,
            services: null,
        },
        warnings: null,
        notes: null,
        lastUpdated: "2024-10-27",
    },
    {
        name: "Gibsons Marina",
        coordinates: {
            // 49.39988983276341, -123.50603523954945
            latitude: 49.3998,
            longitude: -123.5060,
        },
        type: "fuelDock",
        features: {
            phone: "(604) 886-9011",
            hours: null,
            fuelTypes: null,
            services: null,
        },
        warnings: null,
        notes: null,
        lastUpdated: "2024-10-27",
    },
    {
        name: "John Henry’s Marina",
        coordinates: {
            // 49.631638206000645, -124.03184289202771
            latitude: 49.6316,
            longitude: -124.0318,
        },
        type: "fuelDock",
        features: {
            phone: "(604) 883-2336 ",
            hours: null,
            fuelTypes: null,
            services: null,
        },
        warnings: null,
        notes: null,
        lastUpdated: "2024-10-27",
    },
    {
        name: "Buccaneer Marina",
        coordinates: {
            // 49.5322889375084, -123.95538194004781
            latitude: 49.5322,
            longitude: -123.9553,
        },
        type: "fuelDock",
        features: {
            phone: "(604) 885-7888",
            hours: null,
            fuelTypes: null,
            services: null,
        },
        warnings: null,
        notes: null,
        lastUpdated: "2024-10-27",
    },
    {
        name: "Secret Cove Marina",
        coordinates: {
            // 49.53552801719986, -123.96640208674273
            latitude: 49.5354,
            longitude: -123.9663,
        },
        type: "fuelDock",
        features: {
            phone: "(604) 885-3533",
            hours: null,
            fuelTypes: null,
            services: null,
        },
        warnings: null,
        notes: null,
        lastUpdated: "2024-10-27",
    },
    {
        name: "Bedwell Harbour Marina",
        coordinates: {
// 48.74801714471668, -123.22933604921201
            latitude: 48.7480,
            longitude: -123.2293,
        },
        type: "fuelDock",
        features: {
            phone: "(250) 629-2111",
            hours: null,
            fuelTypes: null,
            services: null,
        },
        warnings: null,
        notes: null,
        lastUpdated: "2024-10-27",
    },
    {
        name: "Montague Harbour Marina",
        coordinates: {
            // 48.89205775189295, -123.39227202225041
            latitude: 48.8920,
            longitude: -123.3922,
        },
        type: "fuelDock",
        features: {
            phone: "(250) 539-5733",
            hours: null,
            fuelTypes: null,
            services: null,
        },
        warnings: "Only open on saturdays during off season",
        notes: null,
        lastUpdated: "2024-10-27",
    },
    {
        name: "Ganges Marina",
        coordinates: {
            latitude: 48.8552,
            longitude: -123.4994,
        },
        type: "fuelDock",
        features: {
            phone: "(250) 537-5242",
            hours: null,
            fuelTypes: null,
            services: null,
        },
        warnings: null,
        notes: null,
        lastUpdated: "2024-10-27",
    },
    {
        name: "Thetis Island Marina",
        coordinates: {
            // 48.97756180896776, -123.66971733583019
            latitude: 48.9775,
            longitude: -123.6696,
        },
        type: "fuelDock",
        features: {
            phone: "(250) 246-3464",
            hours: null,
            fuelTypes: null,
            services: null,
        },
        warnings: null,
        notes: null,
        lastUpdated: "2024-10-27",
    },
    {
        name: "Maple Bay Marina",
        coordinates: {
            // 48.79541422131213, -123.60188519498165
            latitude: 48.7953,
            longitude: -123.6018,
        },
        type: "fuelDock",
        features: {
            phone: "(250) 746-8482",
            hours: null,
            fuelTypes: null,
            services: null,
        },
        warnings: null,
        notes: null,
        lastUpdated: "2024-10-27",
    },
    {
        name: "Mill Bay Marina",
        coordinates: {
            // 48.64983431006447, -123.55260688055155
            latitude: 48.6497,
            longitude: -123.5525,
        },
        type: "fuelDock",
        features: {
            phone: "(250) 743-4303",
            hours: null,
            fuelTypes: null,
            services: null,
        },
        warnings: null,
        notes: null,
        lastUpdated: "2024-10-27",
    },
    {
        name: "Van Isle Marina",
        coordinates: {
            // 48.66749097066656, -123.40466480709001
            latitude: 48.6674,
            longitude: -123.4046,
        },
        type: "fuelDock",
        features: {
            phone: "(250) 656-1138",
            hours: null,
            fuelTypes: null,
            services: null,
        },
        warnings: null,
        notes: null,
        lastUpdated: "2024-10-27",
    },
    {
        name: "North Saanich Marina",
        coordinates: {
            // 48.675919167153765, -123.41400641002097
            latitude: 48.6758,
            longitude: -123.4139,
        },
        type: "fuelDock",
        features: {
            phone: "(250) 656-5558",
            hours: null,
            fuelTypes: null,
            services: null,
        },
        warnings: null,
        notes: null,
        lastUpdated: "2024-10-27",
    },
    {
        name: "Fisherman’s Wharf",
        coordinates: {
            // 48.42332250095041, -123.3853647095372
            latitude: 48.4232,
            longitude: -123.3852,
        },
        type: "fuelDock",
        features: {
            phone: "(250) 381-5221",
            hours: null,
            fuelTypes: null,
            services: null,
        },
        warnings: null,
        notes: null,
        lastUpdated: "2024-10-27",
    },
    {
        name: "Port Alberni Marine Fuels",
        coordinates: {
            // 49.23678289325976, -124.81605729254667
            latitude: 49.2367,
            longitude: -124.8160,
        },
        type: "fuelDock",
        features: {
            phone: "(250) 730-3835",
            hours: null,
            fuelTypes: null,
            services: null,
        },
        warnings: null,
        notes: null,
        lastUpdated: "2024-10-27",
    },
    {
        name: "Upnit Lodge",
        coordinates: {
            // 48.82831900691418, -125.13720748359637
            latitude: 48.8283,
            longitude: -125.1371,
        },
        type: "fuelDock",
        features: {
            phone: "(250) 728-3228",
            hours: null,
            fuelTypes: null,
            services: null,
        },
        warnings: null,
        notes: null,
        lastUpdated: "2024-10-27",
    },
    {
        name: "Ostroms Gas Bar",
        coordinates: {
            // 48.82398846393551, -125.13647069871885
            latitude: 48.8239,
            longitude: -125.1363,
        },
        type: "fuelDock",
        features: {
            phone: "(250) 728-3231",
            hours: null,
            fuelTypes: null,
            services: null,
        },
        warnings: null,
        notes: null,
        lastUpdated: "2024-10-27",
    },
    {
        name: "Ucluelet Fuel Dock",
        coordinates: {
            // 48.93835041445688, -125.53603931316104
            latitude: 48.9383,
            longitude: -125.5359,
        },
        type: "fuelDock",
        features: {
            phone: "(250) 726-4262",
            hours: null,
            fuelTypes: null,
            services: null,
        },
        warnings: null,
        notes: null,
        lastUpdated: "2024-10-27",
    },
    {
        name: "Method Marine Supply",
        coordinates: {
            // 49.15481468230951, -125.90426941140937
            latitude: 49.1548,
            longitude: -125.9042,
        },
        type: "fuelDock",
        features: {
            phone: "(250) 725-3251",
            hours: null,
            fuelTypes: null,
            services: null,
        },
        warnings: null,
        notes: null,
        lastUpdated: "2024-10-27",
    },
    {
        name: "Pacific Gateway Marina",
        coordinates: {
            // 48.556300045617306, -124.41519294809518
            latitude: 48.5562,
            longitude: -124.4151,
        },
        type: "fuelDock",
        features: {
            phone: "(250) 412-5509",
            hours: null,
            fuelTypes: null,
            services: null,
        },
        warnings: null,
        notes: null,
        lastUpdated: "2024-10-27",
    },
    {
        name: "Departure Bay Gas n Go",
        coordinates: {
            // 49.18960294187735, -123.94795102386315
            latitude: 49.1895,
            longitude: -123.9478,
        },
        type: "fuelDock",
        features: {
            phone: "(250) 591-0810",
            hours: null,
            fuelTypes: null,
            services: null,
        },
        warnings: null,
        notes: null,
        lastUpdated: "2024-10-27",
    },
    {
        name: "Petro-Canada Marine",
        coordinates: {
            // 49.168338649293915, -123.93397061327957
            latitude: 49.1682,
            longitude: -123.9338,
        },
        type: "fuelDock",
        features: {
            phone: "(250) 754-7828",
            hours: null,
            fuelTypes: null,
            services: null,
        },
        warnings: null,
        notes: null,
        lastUpdated: "2024-10-27",
    },
    {
        name: "Fairwinds Marina",
        coordinates: {
            // 49.28630574654169, -124.13560180633185
            latitude: 49.2862,
            longitude: -124.1355,
        },
        type: "fuelDock",
        features: {
            phone: "(250) 468-5364",
            hours: null,
            fuelTypes: null,
            services: null,
        },
        warnings: null,
        notes: null,
        lastUpdated: "2024-10-27",
    },
    {
        name: "French Creek Marina",
        coordinates: {
            // 49.3499373698745, -124.3572917650911
            latitude: 49.3498,
            longitude: -124.3572,
        },
        type: "fuelDock",
        features: {
            phone: "(250) 248-5051",
            hours: null,
            fuelTypes: null,
            services: null,
        },
        warnings: null,
        notes: null,
        lastUpdated: "2024-10-27",
    },
    {
        name: "Gas N Go Marina",
        coordinates: {
            // 49.67002650655159, -124.92943184371404
            latitude: 49.6700,
            longitude: -124.9293,
        },
        type: "fuelDock",
        features: {
            phone: "(250) 339-4664",
            hours: null,
            fuelTypes: null,
            services: null,
        },
        warnings: null,
        notes: null,
        lastUpdated: "2024-10-27",
    },
    {
        name: "Shell Marine Fuel",
        coordinates: {
            // 50.036105474649375, -125.24524994173726
            latitude: 50.0360,
            longitude: -125.2451,
        },
        type: "fuelDock",
        features: {
            phone: "(250) 339-4664",
            hours: null,
            fuelTypes: null,
            services: null,
        },
        warnings: null,
        notes: null,
        lastUpdated: "2024-10-27",
    },
    {
        name: "Discovery Harbour Fuel Sales",
        coordinates: {
            // 50.03600335284208, -125.2452656000979
            latitude: 50.0360,
            longitude: -125.2451,
        },
        type: "fuelDock",
        features: {
            phone: "(250) 287-3456",
            hours: null,
            fuelTypes: null,
            services: null,
        },
        warnings: null,
        notes: null,
        lastUpdated: "2024-10-27",
    },
    {
        name: "Brown's Bay Marina",
        coordinates: {
            // 50.16207603239194, -125.37387331823646
            latitude: 50.1620,
            longitude: -125.3737,
        },
        type: "fuelDock",
        features: {
            phone: "(250) 286-3135",
            hours: null,
            fuelTypes: null,
            services: null,
        },
        warnings: null,
        notes: null,
        lastUpdated: "2024-10-27",
    },
    {
        name: "Lund Fuel Dock",
        coordinates: {
            // 49.98097908998982, -124.76342711712672
            latitude: 49.9809,
            longitude: -124.7633,
        },
        type: "fuelDock",
        features: {
            phone: null,
            hours: null,
            fuelTypes: null,
            services: null,
        },
        warnings: null,
        notes: null,
        lastUpdated: "2024-10-27",
    },
    {
        name: "Columbia Fuels",
        coordinates: {
            // 49.833985473932664, -124.52894556820003
            latitude: 49.8339,
            longitude: -124.5288,
        },
        type: "fuelDock",
        features: {
            phone: "(604) 485-2867",
            hours: null,
            fuelTypes: null,
            services: null,
        },
        warnings: null,
        notes: null,
        lastUpdated: "2024-10-27",
    },
    {
        name: "Refuge Cove Marina",
        coordinates: {
            // 50.12399128844124, -124.83931201677913
            latitude: 50.1239,
            longitude: -124.8392,
        },
        type: "fuelDock",
        features: {
            phone: "(250) 935-6659",
            hours: null,
            fuelTypes: null,
            services: null,
        },
        warnings: null,
        notes: null,
        lastUpdated: "2024-10-27",
    },
    {
        name: "Lagoon Cove Marina",
        coordinates: {
            // 50.59855334806236, -126.31376107071542
            latitude: 50.5985,
            longitude: -126.3136,
        },
        type: "fuelDock",
        features: {
            phone: "(778) 819-6962",
            hours: null,
            fuelTypes: null,
            services: null,
        },
        warnings: null,
        notes: null,
        lastUpdated: "2024-10-30",
    },
    {
        name: "Sullivan Bay Marina",
        coordinates: {
            // 50.8842653743932, -126.82806856904723
            latitude: 50.8842,
            longitude: -126.8280,
        },
        type: "fuelDock",
        features: {
            phone: "(604) 484-9193",
            hours: null,
            fuelTypes: null,
            services: null,
        },
        warnings: null,
        notes: null,
        lastUpdated: "2024-10-30",
    },
    {
        name: "North Island Marina",
        coordinates: {
            // 50.589568188068704, -127.09016727442642
            latitude: 50.5894,
            longitude: -127.0900,
        },
        type: "fuelDock",
        features: {
            phone: "(250) 956-4044",
            hours: null,
            fuelTypes: null,
            services: null,
        },
        warnings: null,
        notes: null,
        lastUpdated: "2024-10-30",
    },
    {
        name: "Coastail Mountain Fuels",
        coordinates: {
            // 50.72198789546242, -127.4618579188211
            latitude: 50.7219,
            longitude: -127.4617,
        },
        type: "fuelDock",
        features: {
            phone: "(250) 949-9988",
            hours: null,
            fuelTypes: null,
            services: null,
        },
        warnings: null,
        notes: null,
        lastUpdated: "2024-10-30",
    },
    {
        name: "QFN Marina",
        coordinates: {
            // 50.59801522491162, -127.57976269188592
            latitude: 50.5980,
            longitude: -127.5796,
        },
        type: "fuelDock",
        features: {
            phone: "(250) 949-6870",
            hours: null,
            fuelTypes: null,
            services: null,
        },
        warnings: null,
        notes: null,
        lastUpdated: "2024-10-30",
    },
    {
        name: "Zeballos Fuels & Marina",
        coordinates: {
            // 49.97820083259876, -126.84487850866338
            latitude: 49.9781,
            longitude: -126.8447,
        },
        type: "fuelDock",
        features: {
            phone: "(250) 761-2003",
            hours: null,
            fuelTypes: null,
            services: null,
        },
        warnings: null,
        notes: null,
        lastUpdated: "2024-10-30",
    },
    {
        name: "Esperanza Marine",
        coordinates: {
            // 49.87071291898857, -126.74222147631302
            latitude: 49.8706,
            longitude: -126.7421,
        },
        type: "fuelDock",
        features: {
            phone: null,
            hours: null,
            fuelTypes: null,
            services: null,
        },
        warnings: null,
        notes: null,
        lastUpdated: "2024-10-30",
    },
    {
        name: "Critter Cove Marina",
        coordinates: {
            // 49.710883654787935, -126.50571947534115
            latitude: 49.7108,
            longitude: -126.5056,
        },
        type: "fuelDock",
        features: {
            phone: "(250) 283-7364",
            hours: null,
            fuelTypes: null,
            services: null,
        },
        warnings: null,
        notes: null,
        lastUpdated: "2024-10-30",
    },
    {
        name: "Outpost at Winter Harbour",
        coordinates: {
            // 50.5115037471062, -128.02940848072782
            latitude: 50.5114,
            longitude: -128.0292,
        },
        type: "fuelDock",
        features: {
            phone: "(250) 969-4333",
            hours: null,
            fuelTypes: null,
            services: null,
        },
        warnings: null,
        notes: null,
        lastUpdated: "2024-10-30",
    },
    {
        name: "Shearwater Marine Store",
        coordinates: {
            // 52.14708019201082, -128.0887308431643
            latitude: 52.1470,
            longitude: -128.0886,
        },
        type: "fuelDock",
        features: {
            phone: "(250) 957-2300",
            hours: null,
            fuelTypes: null,
            services: null,
        },
        warnings: null,
        notes: null,
        lastUpdated: "2024-10-30",
    },
    {
        name: "Dawsons Landing",
        coordinates: {
            // 51.57472029183901, -127.59221365735702
            latitude: 51.5746,
            longitude: -127.5921,
        },
        type: "fuelDock",
        features: {
            phone: "(604) 629-9897",
            hours: null,
            fuelTypes: null,
            services: null,
        },
        warnings: null,
        notes: null,
        lastUpdated: "2024-10-30",
    },
    {
        name: "First Nations Fuel Dock",
        coordinates: {
            // 52.59418181216197, -128.52151024925584
            latitude: 52.5941,
            longitude: -128.5213,
        },
        type: "fuelDock",
        features: {
            phone: "(250) 839-1233",
            hours: null,
            fuelTypes: null,
            services: null,
        },
        warnings: null,
        notes: null,
        lastUpdated: "2024-10-30",
    },
    {
        name: "Hartley Bay Fuel Dock",
        coordinates: {
            // 53.42406780636947, -129.25203889302236
            latitude: 53.4240,
            longitude: -129.2519,
        },
        type: "fuelDock",
        features: {
            phone: "(250) 841-2675",
            hours: null,
            fuelTypes: null,
            services: null,
        },
        warnings: null,
        notes: null,
        lastUpdated: "2024-10-30",
    },
    {
        name: "Northwest Fuels",
        coordinates: {
            // 54.320011898601045, -130.3180018445833
            latitude: 54.3200,
            longitude: -130.3178,
        },
        type: "fuelDock",
        features: {
            phone: "(250) 624-4106",            
            hours: null,
            fuelTypes: null,
            services: null,
        },
        warnings: null,
        notes: null,
        lastUpdated: "2024-10-30",
    },
    {
        name: "Fast Fuels",
        coordinates: {
            // 53.25343418121234, -132.07599880330548
            latitude: 53.2533,
            longitude: -132.0758,
        },
        type: "fuelDock",
        features: {
            phone: "(250) 559-4611",
            hours: null,
            fuelTypes: null,
            services: null,
        },
        warnings: null,
        notes: null,
        lastUpdated: "2024-10-30",
    },
]

export default fuelDockArray;
