
const buoyArray = [
    {
        name: "Plumper Cove Marine Park",
        coordinates: {
            latitude: 49.4033,
            longitude: -123.4745,
        },
        type: "mooring buoy",
        features: {
            hiking: true,
            camping: true,
            beaches: true,
        },
        warnings: null,
        notes: null,
        boatCapacity: 'medium', // low, medium, high (high is many boats)
        popularity: 'high', // low, medium, high (high is many boats)
        boatSpots: 8, // approximate number of boats that can be accommodated
        score: 80,
        lastUpdated: "2024-10-10",
        links: "https://bcparks.ca/plumper-cove-marine-park/"
    },
    {
        name: "Princess Louisa Inlet",
        coordinates: {
            latitude: 50.1860,
            longitude: -123.8042,
        },
        type: "mooring buoy",
        features: {
            hiking: true,
            camping: true,
            beaches: true,
        },
        warnings: null,
        notes: null,
        boatCapacity: 'low', // low, medium, high (high is many boats)
        popularity: 'high', // low, medium, high (high is many boats)
        boatSpots: 5, // approximate number of boats that can be accommodated
        score: 85,
        lastUpdated: "2024-10-10",
        links: "https://bcparks.ca/princess-louisa-marine-park/"
    },
    {
        name: "Montague Harbour",
        coordinates: {
            latitude: 48.8949,
            longitude: -123.3991,
        },
        type: "mooring buoy",
        features: {
            hiking: true,
            camping: true,
            beaches: true,
        },
        warnings: null,
        notes: null,
        boatCapacity: 'high', // low, medium, high (high is many boats)
        popularity: 'high', // low, medium, high (high is many boats)
        boatSpots: 20, // approximate number of boats that can be accommodated
        score: 90,
        lastUpdated: "2024-10-11",
        links: "https://bcparks.ca/montague-harbour-marine-park/"
    },
    {
        name: "Beaumount Marine Park",
        coordinates: {
            latitude: 48.7524,
            longitude: -123.2345,
        },
        type: "mooring buoy",
        features: {
            hiking: true,
            camping: false,
            beaches: true,
        },
        warnings: "Camping is no longer allowed",
        notes: null,
        boatCapacity: 'medium', // low, medium, high (high is many boats)
        popularity: 'medium', // low, medium, high (high is many boats)
        boatSpots: 12, // approximate number of boats that can be accommodated
        score: 85,
        lastUpdated: "2024-10-11",
        links: null
    },
    {
        name: "Cabbage Island",
        coordinates: {
            latitude: 48.7966,
            longitude: -123.0865,
        },
        type: "mooring buoy",
        features: {
            hiking: false,
            camping: true,
            beaches: true,
        },
        warnings: null,
        notes: null,
        boatCapacity: 'low', // low, medium, high (high is many boats)
        popularity: 'medium', // low, medium, high (high is many boats)
        boatSpots: 9, // approximate number of boats that can be accommodated
        score: 90,
        lastUpdated: "2024-10-11",
        links: null
    },
    {
        name: "Halkett Bay",
        coordinates: {
            latitude: 49.4498,
            longitude: -123.3287,
        },
        type: "mooring buoy",
        features: {
            hiking: true,
            camping: true,
            beaches: true,
        },
        warnings: null,
        notes: null,
        boatCapacity: 'low', // low, medium, high (high is many boats)
        popularity: 'medium', // low, medium, high (high is many boats)
        boatSpots: 1, // approximate number of boats that can be accommodated
        score: 90,
        lastUpdated: "2024-10-30",
        links: null
    },
]

export default buoyArray;