import React, { useState } from "react";

const SlidingSidebar = ({ handleSearch, resetSearchFilters }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [type, setType] = useState(""); // "anchorage", "publicDock", "mooringBuoy"
  const [mobile, setMobile] = useState(window.innerWidth < 600);
  const [searchFilters, setSearchFilters] = useState({
    bottomType: [], // Initialize as an array
    dinghyLanding: false,
    hiking: false,
    camping: false,
    beaches: false,
  });

  const buttonObjects = [
    { type: "anchorage", text: "Anchorage" },
    { type: "publicDock", text: "Public Dock" },
    { type: "mooringBuoy", text: "Mooring Buoy" },
    { type: "fuelDock", text: "Fuel Dock" },
  ];

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  // Function to handle radio button changes
  const handleTypeChange = (e) => {
    const selectedType = e.target.value;
    setType(selectedType);
    // Reset filters based on the type
    if (selectedType === "anchorage") {
      setSearchFilters({
        bottomType: [],
        dinghyLanding: false,
        hiking: false,
        camping: false,
        beaches: false,
      });
    } else if (selectedType === "publicDock") {
      setSearchFilters({
        bottomType: [], // No bottom type for public dock
        dinghyLanding: false,
        hiking: false,
        camping: false,
        beaches: false,
      });
    } else if (selectedType === "mooringBuoy") {
      setSearchFilters({
        bottomType: [], // No bottom type for public dock
        dinghyLanding: false,
        hiking: false,
        camping: false,
        beaches: false,
      });
    } else if (selectedType === "fuelDock") {
      setSearchFilters({
        bottomType: [], // No bottom type for public dock
        dinghyLanding: false,
        hiking: false,
        camping: false,
        beaches: false,
      });
    }
    else {
      setSearchFilters({}); // Reset for Mooring Buoy or other options
    }
  };

  // Handle checkbox change for bottomType
  const handleBottomTypeChange = (e) => {
    const { value, checked } = e.target;
    let newBottomType = [...searchFilters.bottomType];

    if (checked) {
      newBottomType.push(value); // Add the selected bottom type
    } else {
      newBottomType = newBottomType.filter((type) => type !== value); // Remove if unchecked
    }

    setSearchFilters({ ...searchFilters, bottomType: newBottomType });
  };

  const resetSearch = () => {
    resetSearchFilters();
    setType("");
    setSearchFilters({
      bottomType: [],
      dinghyLanding: false,
      hiking: false,
      camping: false,
      beaches: false,
    });
  }

  return (
    <div>
      {/* Sidebar Container */}
      <div className={`sidebar ${isOpen ? "open" : "closed"}`}>
        <div className="content">
          <h2>Select A Destination</h2>
          <div>
            {buttonObjects.map((button) => (
              <label key={button.type}>
                <input
                  type="radio"
                  value={button.type}
                  checked={type === button.type}
                  onChange={handleTypeChange}
                />
                {button.text}
              </label>
            ))}
          </div>

          {/* Conditional filters based on selection */}
          {type === "anchorage" && (
            <div>
              <h3>Anchorage Filters</h3>

              {/* Bottom Type checkboxes */}
              <label>Bottom Type:</label>
              <div>
                <label>
                  <input
                    type="checkbox"
                    value="sand"
                    checked={searchFilters.bottomType.includes("sand")}
                    onChange={handleBottomTypeChange}
                  />
                  Sand
                </label>
                <label>
                  <input
                    type="checkbox"
                    value="mud"
                    checked={searchFilters.bottomType.includes("mud")}
                    onChange={handleBottomTypeChange}
                  />
                  Mud
                </label>
                <label>
                  <input
                    type="checkbox"
                    value="rock"
                    checked={searchFilters.bottomType.includes("rock")}
                    onChange={handleBottomTypeChange}
                  />
                  Rock
                </label>
              </div>
              <label>
                Dinghy Landing:
                <input
                  type="checkbox"
                  checked={searchFilters.dinghyLanding}
                  onChange={(e) =>
                    setSearchFilters({
                      ...searchFilters,
                      dinghyLanding: e.target.checked,
                    })
                  }
                />
              </label>
              <label>
                Hiking:
                <input
                  type="checkbox"
                  checked={searchFilters.hiking}
                  onChange={(e) =>
                    setSearchFilters({
                      ...searchFilters,
                      hiking: e.target.checked,
                    })
                  }
                />
              </label>
              <label>
                Camping:
                <input
                  type="checkbox"
                  checked={searchFilters.camping}
                  onChange={(e) =>
                    setSearchFilters({
                      ...searchFilters,
                      camping: e.target.checked,
                    })
                  }
                />
              </label>
              <label>
                Beaches:
                <input
                  type="checkbox"
                  checked={searchFilters.beaches}
                  onChange={(e) =>
                    setSearchFilters({
                      ...searchFilters,
                      beaches: e.target.checked,
                    })
                  }
                />
              </label>
            </div>
          )}
          {type === "publicDock" && (
            <div>
              <h3>Public Dock Filters</h3>
              <label>
                Hiking:
                <input
                  type="checkbox"
                  checked={searchFilters.hiking}
                  onChange={(e) =>
                    setSearchFilters({
                      ...searchFilters,
                      hiking: e.target.checked,
                    })
                  }
                />
              </label>
              <label>
                Camping:
                <input
                  type="checkbox"
                  checked={searchFilters.camping}
                  onChange={(e) =>
                    setSearchFilters({
                      ...searchFilters,
                      camping: e.target.checked,
                    })
                  }
                />
              </label>
              <label>
                Beaches:
                <input
                  type="checkbox"
                  checked={searchFilters.beaches}
                  onChange={(e) =>
                    setSearchFilters({
                      ...searchFilters,
                      beaches: e.target.checked,
                    })
                  }
                />
              </label>
            </div>
          )}
          {type === "mooringBuoy" && (
            <div>
              <h3>Mooring Buoy Filters</h3>
              <label>
                Hiking:
                <input
                  type="checkbox"
                  checked={searchFilters.hiking}
                  onChange={(e) =>
                    setSearchFilters({
                      ...searchFilters,
                      hiking: e.target.checked,
                    })
                  }
                />
              </label>
              <label>
                Camping:
                <input
                  type="checkbox"
                  checked={searchFilters.camping}
                  onChange={(e) =>
                    setSearchFilters({
                      ...searchFilters,
                      camping: e.target.checked,
                    })
                  }
                />
              </label>
              <label>
                Beaches:
                <input
                  type="checkbox"
                  checked={searchFilters.beaches}
                  onChange={(e) =>
                    setSearchFilters({
                      ...searchFilters,
                      beaches: e.target.checked,
                    })
                  }
                />
              </label>
            </div>
          )}
          {/* Search Button */}
          <button onClick={() => {
            handleSearch(type, searchFilters);
            if(mobile) toggleSidebar();
          }}>
            Search
          </button>        
        </div>
        {type && <div onClick={resetSearch} className="sidebar-clear-btn">Clear Search</div>}
      </div>

      {/* Toggle Button */}
      <div className="toggle-button" onClick={toggleSidebar}>
        <span>{isOpen ? "❮" : "❯"}</span> {/* Arrow Icon */}
      </div>
    </div>
  );
};

export default SlidingSidebar;
