import { FaAnchor, FaWind, FaCampground, FaHiking, FaUmbrellaBeach, FaExclamationTriangle, FaUsers, FaInfoCircle } from 'react-icons/fa'; // Icons for features
import { IoBalloonOutline } from "react-icons/io5";
import { transformKey } from '../../functions/transformKey';

const DestinationsBuoyPopupContent = ({ selectedBuoy }) => {

    return (
        <div style={{ width: '200px' }}>
            <IoBalloonOutline size={20} className='popup-icon' />
            <h3>{selectedBuoy.name}</h3>

            {/* Features */}
            <div><FaUmbrellaBeach /> <strong>Beaches:</strong> {selectedBuoy.features.beaches ? 'Yes' : 'No'}</div>
            <div><FaHiking /> <strong>Hiking:</strong> {selectedBuoy.features.hiking ? 'Yes' : 'No'}</div>
            <div><FaCampground /> <strong>Camping:</strong> {selectedBuoy.features.camping ? 'Yes' : 'No'}</div>

            {/* Warnings */}
            {selectedBuoy.warnings && (
                <div><FaExclamationTriangle /> <strong>Warning:</strong> {selectedBuoy.warnings}</div>
            )}

            {/* Boat Capacity & Popularity */}
            <div><FaUsers /> <strong>Boat Capacity:</strong> {transformKey(selectedBuoy.boatCapacity) || 'Unknown'}</div>
            <div><FaUsers /> <strong>Mooring Buoys:</strong> {selectedBuoy.boatSpots || 'Unknown'}</div>
            <div><FaUsers /> <strong>Popularity:</strong> {transformKey(selectedBuoy.popularity) || 'Unknown'}</div>

            {/* Score */}
            <div><FaInfoCircle /> <strong>Score:</strong> {selectedBuoy.score}/100</div>
        
        </div>
    );
};

export default DestinationsBuoyPopupContent;
