import React, { useState } from 'react';
import SidebarPro from '../global/sidebar';
import BottomNav from '../global/bottomNav';
import Footer from '../global/footer';

const FishingReportJune10 = () => {

  const [mobile, setMobile] = useState(window.innerWidth < 600);

  return (
    <div className='article-narrow-container'>
    {mobile ? <BottomNav /> : <SidebarPro />}
      <div className='article-container'>
        <h2>June 10th Fishing Report - Vancouver Area</h2>
        <p>The warm weather was upon us and the fishing was.. well luke warm if you ask me.</p>
        <p>It might be our short memories and thoughts of June's past but early June has not been as hot as the late June of 2023.</p>
        <h3>Let's Talk About Species</h3>
        <h4>Lingcod</h4>
        <p>Some absolute beasts have been caught off of thrasher this week, one weighing in at approx 30lbs. If you have ever bared witness to a 30lb lingcod you will have a different level of respect for the creature.</p>
        <img src={require('../../images/blog/june 10/ling coho.jpeg')} />
        <h4>Prawning</h4>
        <p>The docks are loaded with commercial boats and land lubbers picking up their spot prawn order. If you are the lucky few with a boat it is still well worth the efforts. Be respectful of where commercial traps are being dropped and find yourself a nice ledge around the 250-350' mark to drop on. I can promise you one thing.. there are still several undiscovered honey holes in our local waters so don't be deterred by a few commercial rigs out there.</p>
        <h4>Chinook</h4>
        <p>These guys are still plentiful out there. I am hearing several opinions that we are in for a record year based on some early sampling/counts. Reminder that these are a closed species local waters and should not be targetted.</p>
        <h4>Coho</h4>
        <p>Coho have arrived, not in the numebrs we were used to last year BUT it is still very early in the season. They seem to appear in pockets and almost always at very shallow depths. Work the 50' and shallower portion and keep your speeds uncomfortably high.. light you shouldnt't be surprised if you caught a tuna kinda thing.</p>
        <p>White hootchies and skinny G spoons have been the ticket so far. Good tip to keep your leaders on the lighter side and go for a 25-30lb test so the lure has more action in the water. A higher lb test creates a stiffer lure with less action.</p>
        <img src={require('../../images/blog/june 10/june 10.jpeg')} />
        <h3>Location, Location, Location</h3>
        <h4>Vancouver Harbour</h4>
        <p>Great reports of crab being caught.. but that's about it</p>
        <h4>West Van / Ambleside</h4>
        <p>Nothing wild going on here yet. A few coho showing up but its a decent spot to take in a view and escape the crowds. Just don't expect to hit your limits.</p>
        <h4>South Bowen</h4>
        <p>Reports of some decent coho fishing in the 50' range. Mostly wild still. Keep your speeds up and work the top 50' of water.</p>
        <h4>Hole in the Wall</h4>
        <p>Nice and easy to access.. but fishing has been meh. Mostly springs with very few coho in this area ..yet.</p>
        <h4>Thrasher</h4>
        <p>Lingcod, coho and spings.. its got em all. Expect some pretty good fishing and hit the tides right for best odds.</p>
        <h4>Agamemnon Channel</h4>
        <p>If the wife, wallet and weather give you permission then take a trip up the coast and try out the channel. Its had some great chinook reports with partial openings throughout the inlet.</p>
        <img src={require('../../images/blog/june 10/june 10 hero.jpeg')} />
        <h3>Key Take Aways</h3>
        <p>Fish fast.. get yourself to the speed you need for coho, then go a little faster.</p>
        <p>Don't give up on prawning.. there is lots to go around and if you don't mind doing a little cartography, you'll find a spot.</p>
        <p>It's a game of numbers. Get family and friends out who may have less experience and give them a chance at some coho. Eventually you'll find a keeper or two.</p>
        <p>Happy fishing, and may your lines always be wet!</p>
        <i>Sporty Inc</i>
      </div>
    </div>
  );
};

export default FishingReportJune10;
