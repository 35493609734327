import { useState } from 'react';
import SidebarPro from '../global/sidebar';
import BottomNav from '../global/bottomNav';

const TermsAndConditions = () => {

    const [mobile, setMobile] = useState(window.innerWidth < 600);

    return (

        <div className='article-narrow-container'>
            {mobile ? <BottomNav /> : <SidebarPro />}
            <div className='article-container'>
                <h2 style={{fontSize: 30}}>Terms & Conditions</h2>
                <p>
                By using the Sporty App services, you agree to comply with these
                Terms & Conditions and any other policies we may post on the app or
                website from time to time. We may change these policies at any time by
                updating this document, and your continued use of our services after any
                changes signifies your acceptance of those changes.
                </p>
        
                <h3>1. Disclaimer</h3>
        
                <h4>1.1 Service Availability:</h4>
                <p>
                Access to the app and services may be interrupted occasionally. We do
                not guarantee the uninterrupted availability or performance of the app,
                weather data, fishing regulations, or other services.
                </p>
        
                <h4>1.2 Weather Data Accuracy:</h4>
                <p>
                Weather forecasts provided are based on best efforts, but due to the
                nature of forecasting, uncertainty is unavoidable. The weather data is
                provided “as is” and without warranties of any kind, including accuracy
                or fitness for a particular purpose. You acknowledge that you assume all
                risks from the use of this data.
                </p>
        
                <h4>1.3 Third-Party Data:</h4>
                <p>
                Data such as anchorage locations, dock facilities, tidal information,
                and other marine data may come from third-party sources. We are not
                responsible for the accuracy or completeness of this information.
                </p>
        
                <h4>1.4 Fishing Regulations:</h4>
                <p>
                Fishing rules are provided based on publicly available information, and
                we strive to ensure accuracy. However, it is the user’s responsibility
                to verify local fishing regulations before engaging in any activity. We
                do not accept liability for incorrect or outdated information.
                </p>
        
                <h4>1.5 Navigational Data:</h4>
                <p>
                Depth, docking, and other maritime-related data should be used in
                conjunction with official navigational resources. We do not accept
                responsibility for navigational errors or any related consequences.
                </p>
        
                <h3>2. Prohibited Use</h3>
        
                <h4>2.1 Personal Use:</h4>
                <p>
                You are granted a limited, non-exclusive license to use the data
                provided by the app for personal, non-commercial purposes only.
                </p>
        
                <h4>2.2 No Redistribution:</h4>
                <p>
                You may not reproduce, sell, or otherwise distribute the data or content
                provided by the app to third parties without written permission.
                </p>
        
                <h4>2.3 Lawful Use:</h4>
                <p>
                You agree to use the app and its services in compliance with all local
                laws and regulations.
                </p>
        
                <h3>3. Account Registration</h3>
        
                <h4>3.1</h4>
                <p>
                To access certain features of the app, you must create an account. You
                are responsible for maintaining the confidentiality of your account
                credentials and for all activities conducted under your account.
                </p>
        
                <h4>3.2</h4>
                <p>
                We reserve the right to suspend or terminate your account if we suspect
                any breach of our policies.
                </p>
        
                <h3>4. Payment and Subscription</h3>
        
                <h4>4.1 Subscription Tiers:</h4>
                <p>
                Sporty App offers free and premium services. Detailed descriptions
                of available plans and their associated pricing can be found within the
                app.
                </p>
        
                <h4>4.2 Billing:</h4>
                <p>
                You agree to provide current and accurate billing information for any
                paid services. Subscriptions automatically renew unless canceled before
                the end of the billing cycle.
                </p>
        
                <h4>4.3 Refunds:</h4>
                <p>
                We offer a 30-day refund policy for first-time subscribers who are
                dissatisfied with the service. This does not apply to renewals or repeat
                subscriptions.
                </p>
        
                <h3>5. Data Usage & Privacy</h3>
        
                <h4>5.1 Data Collection:</h4>
                <p>
                We collect personal data necessary for account creation, usage
                analytics, and improving our services. By using this app, you agree to
                our data collection practices as described in our Privacy Policy.
                </p>
        
                <h4>5.2 Third-Party Services:</h4>
                <p>
                The app may include links to third-party services, such as GPS tracking,
                weather data providers, and more. We do not endorse or control
                third-party services and are not responsible for their content.
                </p>
        
                <h3>6. Limitation of Liability</h3>
        
                <p>
                To the fullest extent permitted by law, Sporty App disclaims
                liability for any damages, including direct, indirect, or consequential
                losses, arising from the use or inability to use the app, data
                inaccuracies, or user reliance on any data provided by the app.
                </p>
        
                <h3>7. Modifications to the Service</h3>
        
                <p>
                We reserve the right to modify, suspend, or discontinue any part of the
                app at any time, without notice. This includes but is not limited to
                changes to the data sources, UI elements, or any functionality.
                </p>
        
                <h3>8. Governing Law</h3>
        
                <p>
                These Terms & Conditions are governed by and construed in accordance
                with the laws of British Columbia, Canada. Any disputes related to the use of
                the app will be subject to the exclusive jurisdiction of the courts of
                British Columbia, Canada.
                </p>
            </div>
        </div>
    );

  };
  
  export default TermsAndConditions;
  