import { RiGasStationLine } from "react-icons/ri";

const DestinationsFuelDockPopupContent = ({ selectedFuelDock }) => {
    
      
    return (

        <div className='gov-anchorage-popup'>
            <div style={{fontWeight: 'bold', marginRight: 10}}>{selectedFuelDock.name}</div>
            <RiGasStationLine size={20} style={{marginRight: 10}} />
        </div>

    );
};

export default DestinationsFuelDockPopupContent;
