const WeatherLegendBar = () => {
    return (
    <div className="legend-bar">
        <div className="legend-item">
          <span className="wind-color-box" style={{ backgroundColor: 'white' }}></span> Wind Station
        </div>
        <div className="legend-item">
          <span className="wave-color-box" style={{ backgroundColor: 'rgb(62, 62, 62)' }}></span> Wave Buoy
        </div>
    </div>
    );
  };

  export default WeatherLegendBar;